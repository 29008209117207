import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';

import { IFirestoreDocumentService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDocumentServerService
  implements IFirestoreDocumentService
{
  getStaticDocument$<T>(document: string): Observable<T> {
    return from([null]);
  }
}
