import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  limit,
  orderBy,
  query,
  setDoc,
  startAt,
  where,
  addDoc
} from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';

// 3rd party
import { firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import {
  NzDrawerOptions,
  NzDrawerRef,
  NzDrawerService
} from 'ng-zorro-antd/drawer';

// Libs
import {
  ISlugRequest,
  ContentEvent,
  OnboardingCopyBlock,
  EditableTheme,
  IUserContext,
  PublishState,
  objectIdFromDate,
  IAnnouncementDTO,
  ApiSurfaces,
  uuidv4,
  IFeatureFlag,
  FIREBASE_COLLECTIONS,
  Funnel,
  IWhitelistAccountsResults,
  NorbyVersion,
  ISetNlqExampleQuestion,
  INlqExampleQuestionListingResults,
  NlqExampleQuestion,
  ENDPOINTS,
  ExecuteExampleQuestionDto,
  IExploreSection,
  CreateNlqRecurringReportDto,
  NlqRecurringReport,
  GetNlqRecurringReportDto,
  NlqRecurringReportResults,
  TestNlqRecurringReportDto,
  NlqInternalQueryDto,
  NlqInternalQuery
} from 'models';
import { ModalService, ErrorService, MessageService, MessageType } from 'uikit';
import { ApiService, UiService, AuthService } from 'shared';

// App
import {
  IFeaturePermission,
  INlqExampleQuestionSearchParams,
  INlqExampleQuestionSearchResults,
  INlqInternalQuerySearchParams,
  INlqInternalQuerySearchResults,
  ISlugResults,
  NorbyFeature,
  ProposeInternalQueryResponseDto
} from '@super-admin/types';
import { ISuperAdminService } from './super-admin.interface.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService implements ISuperAdminService {
  private _activeDrawers: NzDrawerRef[] = [];

  onboardingCopyManagerComponent;
  onboardingFileLibraryComponent;
  themeBuilderManagerComponent;
  loginComponent;
  createEditFeatureFlagComponent;
  exampleQuestionTesterComponent;
  exampleQuestionEditorComponent;
  recurringReportEditorComponent;
  sendTestReportComponent;
  sendReportComponent;

  constructor(
    private _api: ApiService,
    private _dialog: NzModalService,
    private _drawer: NzDrawerService,
    private _rootDialog: ModalService,
    private _firestore: Firestore,
    private _auth: AuthService,
    private _ui: UiService,
    private _message: MessageService,
    private _router: Router,
    private _error: ErrorService
  ) {}

  closeDrawer() {
    for (const drawer of this._activeDrawers) {
      drawer.close();
    }
  }

  createDrawer(component: any, options: NzDrawerOptions): Promise<any> {
    const prevDrawers = this._activeDrawers;
    const newDrawer = this._drawer.create<typeof component, string>({
      nzContent: component,
      nzCloseOnNavigation: true,
      nzBodyStyle: {
        padding: '0',
        height: '100vh',
        maxHeight: '-webkit-fill-available'
      },
      nzWrapClassName: 'norby-page-drawer',
      nzPlacement: 'bottom',
      nzWidth: '100%',
      nzHeight: '100%',
      nzClosable: false,
      nzTitle: null,
      nzOnCancel: async () => {
        // const cmp = newDrawer.getContentComponent();
        // if (cmp instanceof FormDrawer) return await cmp.onCancel();

        return true;
      },
      ...options
    });

    prevDrawers.push(newDrawer);
    return newDrawer.afterClose.toPromise().then((value) => {
      prevDrawers.pop();
      return value;
    });
  }

  // Only available to super users
  // Specify a slug and provision a new site
  async provisionSite(input: {
    slug: string;
    slugRequestId?: string;
    projectTier?: string;
    tld?: string;
  }): Promise<string> {
    try {
      await this._api.post(ApiSurfaces.API, '/admin/provision_slug', input);
      this._message.show({
        text: `Created ${input.slug}`,
        type: MessageType.SUCCESS
      });
      return input.slug;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async sendOnboardingInvite(input: {
    phoneNumber?: string;
    email?: string;
    slug: string;
    referrerSlug: string;
  }) {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        '/organization/member/admin_invite',
        {
          ...input,
          role: 'administrator'
        }
      );
      this._message.show({
        text: 'Invitation sent',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async deleteSlugRequest(request: ISlugRequest) {
    const ref = doc(this._firestore, 'slugRequests', request?.id);
    return deleteDoc(ref);
  }

  async toggleSlugRequestPriority(request: ISlugRequest) {
    const newPriority = !request.priority;
    const ref = doc(this._firestore, 'slugRequests', request?.id);
    return setDoc(ref, { priority: newPriority }, { merge: true });
  }

  getSlugRequestsPendingProvision$(
    priority = true
  ): Observable<ISlugRequest[]> {
    const ref = collection(this._firestore, 'slugRequests');
    const constraints = [
      where('flagged', '==', true),
      where('hasBeenProvisioned', '==', false),
      where('hasBeenOnboarded', '==', false),
      where('priority', '==', priority),
      orderBy('createdAtCursor', 'desc'),
      limit(1000)
    ];
    return collectionData(query(ref, ...constraints)).pipe(
      map((list) => list.map((req) => plainToClass(ISlugRequest, req)))
    );
  }

  getSlugRequestsPendingInvitation$(
    priority = true
  ): Observable<ISlugRequest[]> {
    const ref = collection(this._firestore, 'slugRequests');
    const constraints = [
      where('hasBeenProvisioned', '==', true),
      where('hasBeenOnboarded', '==', false),
      where('priority', '==', priority),
      orderBy('createdAtCursor', 'desc'),
      limit(1000)
    ];
    return collectionData(query(ref, ...constraints)).pipe(
      map((list) => list.map((req) => plainToClass(ISlugRequest, req)))
    );
  }

  // Only available to super users
  // Reset a subscription
  async resetSubscription(input: {
    slug: string;
    projectTier?: string;
  }): Promise<boolean> {
    try {
      await this._api.post(ApiSurfaces.API, '/admin/reset_subscription', input);
      this._message.show({
        text: 'Reset',
        type: MessageType.SUCCESS
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getEnrollmentsForFeature$(feature: string): Observable<IFeaturePermission[]> {
    const ref = collection(this._firestore, 'featurePermissions');
    const constraints = [
      where('feature', '==', feature),
      where('permitted', '==', true),
      orderBy('permittedAt', 'desc')
    ];
    return collectionData(query(ref, ...constraints)).pipe(
      map((list) => list.map((req) => plainToClass(IFeaturePermission, req)))
    );
  }

  async setFeaturePermissions(featureReq: {
    permitted: boolean;
    slug: string;
    feature: NorbyFeature;
  }): Promise<void> {
    try {
      await this._api.post(
        ApiSurfaces.API,
        '/admin/set_feature_permission',
        featureReq
      );
      this._message.show({
        text: `Successfully updated ${featureReq.slug}`,
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getWhitelistedAccounts = async (filter?: {
    limit?: number;
    startAfter?: string;
    offset?: number;
  }): Promise<IWhitelistAccountsResults> => {
    try {
      return await this._api.get<IWhitelistAccountsResults>(
        ApiSurfaces.API,
        '/admin/messaging_pre_approval',
        filter
      );
    } catch (e) {
      this._error.displayError(e);
    }
  };

  async whitelistAccount(request: {
    approved: boolean;
    slug: string;
  }): Promise<void> {
    try {
      await this._api.post(
        ApiSurfaces.API,
        '/admin/messaging_pre_approval',
        request
      );
      this._message.show({
        text: `Successfully approved ${request.slug}`,
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getUpcomingEvents$(): Observable<ContentEvent[]> {
    const ref = collection(this._firestore, 'content');
    const constraints = [
      where('published', '==', true),
      orderBy('startDateCursor', 'asc'),
      startAt(objectIdFromDate(new Date())),
      limit(50)
    ];
    return collectionData(query(ref, ...constraints)).pipe(
      map((l) => l.map((e) => ContentEvent.fromObject(e)))
    );
  }

  async deleteTheme(theme: EditableTheme) {
    if (!theme?.id) {
      return;
    }

    const ref = doc(this._firestore, FIREBASE_COLLECTIONS.themes, theme.id);
    return deleteDoc(ref);
  }

  getOnboardingCopies$(
    version?: NorbyVersion,
    funnel?: Funnel
  ): Observable<OnboardingCopyBlock[]> {
    const constraints = [
      ...(funnel ? [where('funnel', '==', funnel)] : []),
      ...(version ? [where('version', '==', version)] : []),
      orderBy('copyName', 'asc'),
      limit(500)
    ];
    const ref = collection(this._firestore, 'onboardingCopy');
    return collectionData(query(ref, ...constraints)).pipe(
      map((l) => l as OnboardingCopyBlock[])
    );
  }

  async getOnboardingCopyById(id: string): Promise<OnboardingCopyBlock> {
    try {
      const ref = doc(this._firestore, 'onboardingCopy', id);
      const snapshot = await getDoc(ref);
      return snapshot.data() as OnboardingCopyBlock;
    } catch (e) {
      return null;
    }
  }

  async setPromptsForSlug(slug: string, questions: string[]): Promise<boolean> {
    try {
      const endpoint = `admin/example_questions`;
      const ret = await this._api.put<boolean>(ApiSurfaces.API, endpoint, {
        slug,
        questions
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getNorbyChatSlugs(
    limit = 10,
    startAfter: string = null
  ): Promise<ISlugResults> {
    try {
      const endpoint = `admin/norby_next_accounts`;
      const ret = await this._api.get<ISlugResults>(ApiSurfaces.API, endpoint, {
        limit,
        ...(startAfter && { startAfter })
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getContentDownloadCsvUrl(contentId: string) {
    try {
      const endpoint = `admin/content/${contentId}/csv`;
      const ret = (
        await this._api.get<{ url: string }>(ApiSurfaces.API, endpoint)
      )?.url;
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async editOrCreateOnboardingCopy(
    id: string,
    copy: OnboardingCopyBlock
  ): Promise<void> {
    try {
      const ref = doc(this._firestore, 'onboardingCopy', id);
      await setDoc(ref, { ...copy, id }, { merge: true });
      this._message.show({
        text: 'Saved flow',
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._message.show({
        text: e,
        type: MessageType.ERROR
      });
    }
  }

  async deleteOnboardingCopy(copyId: string) {
    const ref = doc(this._firestore, 'onboardingCopy', copyId);
    return deleteDoc(ref);
  }

  async editOrCreateTheme(theme?: EditableTheme): Promise<void> {
    try {
      const id = theme?.id ?? uuidv4();
      const ref = doc(this._firestore, FIREBASE_COLLECTIONS.themes, id);
      await setDoc(ref, { ...theme.toObject(), id }, { merge: true });
      this._message.show({
        text: 'Saved theme',
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._message.show({
        text: e,
        type: MessageType.ERROR
      });
    }
  }

  async launchOnboardingCopyManager(copy?: OnboardingCopyBlock): Promise<void> {
    if (!this.onboardingCopyManagerComponent) {
      this._ui.setLoading(true);
      const { EditOnboardingCopyComponent } = await import(
        '../../entry-points/edit-onboarding-copy'
      );
      this.onboardingCopyManagerComponent = EditOnboardingCopyComponent;
      this._ui.setLoading(false);
    }

    const component = this.onboardingCopyManagerComponent;
    this.createDrawer(component, {
      nzContentParams: { copy }
    });
  }

  async launchCreateOrEditThemeFlow(theme?: EditableTheme): Promise<void> {
    if (!this.themeBuilderManagerComponent) {
      this._ui.setLoading(true);
      const { ThemeBuilderManagerComponent } = await import(
        '../../entry-points/theme-builder-manager'
      );

      this.themeBuilderManagerComponent = ThemeBuilderManagerComponent;
      this._ui.setLoading(false);
    }

    const component = this.themeBuilderManagerComponent;
    this.createDrawer(component, {
      nzContentParams: { theme }
    });
  }

  getThemes$(publishState?: PublishState): Observable<EditableTheme[]> {
    const ref = collection(this._firestore, FIREBASE_COLLECTIONS.themes);
    const constraints =
      publishState === 'published'
        ? [where('published', '==', true)]
        : publishState === 'draft'
          ? [where('published', '==', false), where('archived', '==', false)]
          : publishState === 'archived'
            ? [where('archived', '==', true)]
            : [];
    return collectionData(query(ref, ...constraints)).pipe(
      map((list) => list.map((req) => plainToClass(EditableTheme, req)))
    );
  }

  // Whether the user is a global admin
  userIsGlobalAdmin$(): Observable<boolean> {
    return this._auth.user$.pipe(
      switchMap(async (u) => this._userIsGlobalAdmin(u))
    );
  }

  userIsGlobalEditor$(): Observable<boolean> {
    return this._auth.user$.pipe(
      switchMap(async (u) => this._userIsGlobalEditor(u))
    );
  }

  userIsGlobalDeveloper$(): Observable<boolean> {
    return this._auth.user$.pipe(
      switchMap(async (u) => this._userIsGlobalDeveloper(u))
    );
  }

  async userIsGlobalAdmin(): Promise<boolean> {
    const user = this._auth.currentUser;
    return this._userIsGlobalAdmin(user);
  }

  async userIsGlobalEditor(): Promise<boolean> {
    const user = this._auth.currentUser;
    return this._userIsGlobalEditor(user);
  }

  async userIsGlobalDeveloper(): Promise<boolean> {
    const user = this._auth.currentUser;
    return this._userIsGlobalDeveloper(user);
  }

  private async _userIsGlobalAdmin(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    return (tokenResult?.claims as IUserContext)?.admin;
  }

  private async _userIsGlobalEditor(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    const claims = tokenResult?.claims as IUserContext;
    return claims?.admin || claims?.editor;
  }

  private async _userIsGlobalDeveloper(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    const claims = tokenResult?.claims as IUserContext;
    return claims?.admin || claims?.developer;
  }

  async launchLoginFlow(): Promise<boolean> {
    if (this._auth.userLoggedIn) return true;
    if (!this.loginComponent) {
      const { LoginComponent } = await import(
        /* webpackPrefetch: true */
        'shared'
      );
      this.loginComponent = LoginComponent;
    }

    const component = this.loginComponent;
    const dialogRef = this._rootDialog.open(component, null);

    const ret = await firstValueFrom(dialogRef.afterClosed$);
    if (ret) {
      this._message.show({
        text: 'Welcome back',
        type: MessageType.SUCCESS
      });
    }
    return ret;
  }

  logoutAndGoHome(): Promise<void> {
    this._router.navigate(['/']);
    return this._auth.logout();
  }

  async editOrCreateAnnouncement(
    announcement?: IAnnouncementDTO
  ): Promise<void> {
    try {
      if (!announcement?.id) {
        const ref = collection(this._firestore, 'announcements');
        await addDoc(ref, { ...announcement });
        this._message.show({
          text: 'Created announcement',
          type: MessageType.SUCCESS
        });
      } else {
        const ref = doc(this._firestore, 'announcements', announcement?.id);
        await setDoc(ref, { ...announcement }, { merge: true });
        this._message.show({
          text: 'Updated announcement',
          type: MessageType.SUCCESS
        });
      }
    } catch (e) {
      this._message.show({
        text: e,
        type: MessageType.ERROR
      });
    }
  }

  async deleteAnnouncement(item: IAnnouncementDTO) {
    const ref = doc(this._firestore, 'announcements', item?.id);
    return deleteDoc(ref);
  }

  fetchAnnouncements$(): Observable<IAnnouncementDTO[]> {
    const ref = collection(this._firestore, 'announcements');
    const constraints = [limit(50)];
    return collectionData(query(ref, ...constraints), { idField: 'id' }).pipe(
      map((list) => list.map((req) => plainToClass(IAnnouncementDTO, req)))
    );
  }

  async launchOnboardingFileLibrary(
    acceptedType?: string
  ): Promise<{ url: string; type: string; file: File }> {
    if (!this.onboardingFileLibraryComponent) {
      this._ui.setLoading(true);
      const { OnboardingFileLibraryComponent } = await import(
        '../../entry-points/onboarding-file-library'
      );

      this.onboardingFileLibraryComponent = OnboardingFileLibraryComponent;
      this._ui.setLoading(false);
    }

    const component = this.onboardingFileLibraryComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzTitle: 'Content Library',
      nzFooter: null,
      nzWidth: '800px',
      nzData: { acceptedType },
      nzCloseIcon: 'feather/x'
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async flushRedisCache(): Promise<boolean> {
    try {
      await this._api.post(ApiSurfaces.API, '/admin/flush_site_cache', {});
      this._message.show({
        text: 'Cache purged',
        type: MessageType.SUCCESS
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async launchFeatureFlagEditor(featureFlag?: IFeatureFlag): Promise<void> {
    if (!this.createEditFeatureFlagComponent) {
      this._ui.setLoading(true);
      const { CreateEditFeatureFlagComponent } = await import(
        '../../entry-points/create-edit-feature-flag'
      );
      this.createEditFeatureFlagComponent = CreateEditFeatureFlagComponent;
      this._ui.setLoading(false);
    }

    const component = this.createEditFeatureFlagComponent;
    this._dialog.create<typeof component>({
      nzContent: component,
      nzTitle: `${featureFlag ? 'Edit' : 'Create'} Feature Flag`,
      nzData: { featureFlag },
      nzCloseIcon: 'feather/x',
      nzOkText: 'Save'
    } as ModalOptions);
  }

  async getExampleQuestions(
    args: {
      slug?: string;
      category?: string;
      limit?: number;
      after?: string;
      offset?: number;
    } = { limit: 10 }
  ): Promise<INlqExampleQuestionListingResults> {
    try {
      const ret = await this._api.get<INlqExampleQuestionListingResults>(
        ApiSurfaces.API,
        '/admin/example_questions',
        args
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async launchEditExampleQuestion(
    exampleQuestion: NlqExampleQuestion
  ): Promise<NlqExampleQuestion> {
    if (!this.exampleQuestionEditorComponent) {
      this._ui.setLoading(true);
      const { EditExampleQuestionComponent } = await import(
        '../../entry-points/edit-example-question'
      );
      this.exampleQuestionEditorComponent = EditExampleQuestionComponent;
      this._ui.setLoading(false);
    }

    const component = this.exampleQuestionEditorComponent;
    const newOrEdit = exampleQuestion?.id ? 'Edit' : 'New';
    const workflowOrChild = exampleQuestion?.parentId
      ? 'customization'
      : 'workflow';
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzTitle: `${newOrEdit} ${workflowOrChild}`,
      nzData: { exampleQuestion },
      nzCloseIcon: 'feather/x',
      nzOkText: 'Save',
      nzWidth: '600px'
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchExampleQuestionTester(
    exampleQuestion: ExecuteExampleQuestionDto,
    slug?: string
  ): Promise<void> {
    if (!this.exampleQuestionTesterComponent) {
      this._ui.setLoading(true);
      const { TestExampleQuestionComponent } = await import(
        '../../entry-points/test-example-question'
      );
      this.exampleQuestionTesterComponent = TestExampleQuestionComponent;
      this._ui.setLoading(false);
    }

    const component = this.exampleQuestionTesterComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzTitle: 'Test workflow',
      nzData: { exampleQuestion, slug },
      nzCloseIcon: 'feather/x',
      nzOkText: 'Done',
      nzWidth: '800px'
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async testInternalQuery(
    internalQuery: NlqInternalQueryDto,
    slug: string,
    prompt: string
  ): Promise<string> {
    try {
      const payload = { slug, internalQuery, prompt };
      const ret = await this._api.post<{ result: string }>(
        ApiSurfaces.ANALYTICS,
        '/ai/test_internal_query',
        payload
      );
      return ret?.result;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async testExampleQuestion(data: ExecuteExampleQuestionDto): Promise<string> {
    try {
      const ret = await this._api.post<{ result: string }>(
        ApiSurfaces.ANALYTICS,
        '/ai/test_example_question',
        data
      );
      return ret?.result;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async createExampleQuestion(
    data: ISetNlqExampleQuestion
  ): Promise<NlqExampleQuestion> {
    try {
      const ret = await this._api.post<NlqExampleQuestion>(
        ApiSurfaces.API,
        '/admin/example_questions',
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async updateExampleQuestion(
    id: string,
    data: Partial<ISetNlqExampleQuestion>
  ): Promise<NlqExampleQuestion> {
    try {
      const ret = await this._api.patch<NlqExampleQuestion>(
        ApiSurfaces.API,
        `/admin/example_questions/${id}`,
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async deleteExampleQuestion(id: string): Promise<boolean> {
    try {
      const ret = await this._api.delete<boolean>(
        ApiSurfaces.API,
        `/admin/example_questions/${id}`
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async runRegressionTestsForSlug(slug: string): Promise<boolean> {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.ADMIN_NLQ,
        '/admin/ai/regression_tests',
        { slug }
      );
      if (ret) {
        this._message.show({
          text: 'Tests are running. Check Slack for updates.',
          type: MessageType.SUCCESS
        });
      }
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async searchInternalQueries(
    queryFilters: INlqInternalQuerySearchParams
  ): Promise<INlqInternalQuerySearchResults> {
    return this._api.get(
      ApiSurfaces.API,
      ENDPOINTS.search.internalQueries,
      queryFilters
    );
  }

  async searchExampleQuestions(
    queryFilters: INlqExampleQuestionSearchParams
  ): Promise<INlqExampleQuestionSearchResults> {
    return this._api.get(
      ApiSurfaces.API,
      ENDPOINTS.search.exampleQuestions,
      queryFilters
    );
  }

  async setExploreSection(sections: IExploreSection[]): Promise<void> {
    try {
      const ref = doc(this._firestore, 'static', 'explore');
      await setDoc(ref, { sections }, { merge: false });
      this._message.show({
        text: 'Published',
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._message.show({
        text: e,
        type: MessageType.ERROR
      });
    }
  }

  async proposeInternalQuery(
    data: NlqInternalQueryDto
  ): Promise<ProposeInternalQueryResponseDto> {
    try {
      const ret = await this._api.post<ProposeInternalQueryResponseDto>(
        ApiSurfaces.API,
        '/admin/propose_internal_query',
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async searchRecurringReports(
    queryFilters: GetNlqRecurringReportDto
  ): Promise<NlqRecurringReportResults> {
    return this._api.get(
      ApiSurfaces.API,
      '/search/admin/nlq_recurring_report',
      queryFilters
    );
  }

  async createRecurringReport(
    data: CreateNlqRecurringReportDto
  ): Promise<NlqRecurringReport> {
    try {
      const ret = await this._api.post<NlqRecurringReport>(
        ApiSurfaces.API,
        '/admin/recurring_report',
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async updateRecurringReport(
    id: string,
    data: Partial<CreateNlqRecurringReportDto>
  ): Promise<NlqRecurringReport> {
    try {
      const ret = await this._api.patch<NlqRecurringReport>(
        ApiSurfaces.API,
        `/admin/recurring_report/${id}`,
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async deleteRecurringReport(id: string): Promise<boolean> {
    try {
      const ret = await this._api.delete<boolean>(
        ApiSurfaces.API,
        `/admin/recurring_report/${id}`
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async launchEditRecurringReportFlow(
    report?: NlqRecurringReport
  ): Promise<NlqRecurringReport> {
    if (!this.recurringReportEditorComponent) {
      this._ui.setLoading(true);
      const { EditReportComponent } = await import(
        '../../entry-points/edit-report'
      );

      this.recurringReportEditorComponent = EditReportComponent;
      this._ui.setLoading(false);
    }

    const component = this.recurringReportEditorComponent;
    const ret = await this.createDrawer(component, {
      nzContentParams: { report }
    });

    return ret;
  }

  async launchSendTestFormDialog(report: NlqRecurringReport): Promise<void> {
    if (!this.sendTestReportComponent) {
      this._ui.setLoading(true);
      const { SendTestFormComponent } = await import(
        '../../entry-points/send-test-form'
      );

      this.sendTestReportComponent = SendTestFormComponent;
      this._ui.setLoading(false);
    }

    const component = this.sendTestReportComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Send',
      nzData: {
        report
      }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchSendReportFormDialog(report: NlqRecurringReport): Promise<void> {
    if (!this.sendReportComponent) {
      this._ui.setLoading(true);
      const { SendReportFormComponent } = await import(
        '../../entry-points/send-report-form'
      );

      this.sendReportComponent = SendReportFormComponent;
      this._ui.setLoading(false);
    }

    const component = this.sendReportComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Send',
      nzData: {
        report
      }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async testRecurringReport(data: TestNlqRecurringReportDto): Promise<boolean> {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        '/admin/recurring_report/test',
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async sendRecurringReport(data: TestNlqRecurringReportDto): Promise<boolean> {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        '/admin/recurring_report/one_off',
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getWorkflowExportDownloadCsvUrl(
    params: INlqExampleQuestionSearchParams
  ) {
    try {
      const endpoint = 'admin/example_questions/export';
      const ret = (
        await this._api.get<{ url: string }>(ApiSurfaces.API, endpoint, params)
      )?.url;
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getReportExportDownloadCsvUrl(params: GetNlqRecurringReportDto) {
    try {
      const endpoint = 'admin/nlq_recurring_reports/export';
      const ret = (
        await this._api.get<{ url: string }>(ApiSurfaces.API, endpoint, params)
      )?.url;
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }
}
