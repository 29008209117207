import { Injectable } from '@angular/core';

// Libs
import {
  ConversationMessage,
  IConversationMessagesResults,
  IConversationResults,
  MessageDto,
  MessageResponse,
  PaginatedMessageFilters,
  PaginatedQueryFilters,
  RateAIConversationMessageDTO,
  ReplyToConversationDto,
  ReplyToConversationResponse,
  StartConversationDto,
  StartConversationResponseDto
} from 'models';
import { IAiService } from './ai.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AiServerService implements IAiService {
  async startConversation(
    data: StartConversationDto
  ): Promise<StartConversationResponseDto> {
    return null;
  }

  async replyToConversation(
    conversationId: string,
    data: ReplyToConversationDto
  ): Promise<ReplyToConversationResponse> {
    return null;
  }

  async shortenMessage(data: MessageDto): Promise<MessageResponse> {
    return null;
  }

  async rateMessage(
    conversationId: string,
    messageId: string,
    data: RateAIConversationMessageDTO
  ): Promise<boolean> {
    return null;
  }

  async getConversations(
    input: PaginatedQueryFilters
  ): Promise<IConversationResults> {
    return null;
  }

  async getConversationMessagesById(
    input: PaginatedMessageFilters
  ): Promise<IConversationMessagesResults> {
    return null;
  }

  async getConversationById({
    id
  }: {
    id: string;
  }): Promise<IConversationMessagesResults> {
    return null;
  }

  async trackGoogleUserConsent(): Promise<boolean> {
    return null;
  }

  async toggleMessagePrivacy(
    messageId: string,
    isPublic: boolean
  ): Promise<ConversationMessage> {
    return null;
  }
}
