import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiveThemedDirectiveModule, ReportDetailViewModule } from 'uikit';
import { DevicePreviewViewModule } from './device-preview-view.module';
import { ReportsPreviewComponent } from '../components/organisms/reports-preview/reports-preview.component';

@NgModule({
  declarations: [ReportsPreviewComponent],
  imports: [
    CommonModule,
    LiveThemedDirectiveModule,
    DevicePreviewViewModule,
    ReportDetailViewModule
  ],
  exports: [ReportsPreviewComponent]
})
export class ReportsPreviewModule {}
