import { Injectable } from '@angular/core';

// 3rd party
import { from, Observable } from 'rxjs';

// Libs
import { ISlugMetadata, OnboardingCopyBlock } from 'models';

@Injectable({
  providedIn: 'root'
})
export class AdminQueriesServerService {
  constructor() {}

  getCurrentSlugMetadata$(): Observable<ISlugMetadata> {
    return from([null]);
  }

  async getOnboardingCopyById(id: string): Promise<OnboardingCopyBlock> {
    return null;
  }
}
