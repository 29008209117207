import { Injectable } from '@angular/core';

// 3rd party
import { Observable } from 'rxjs';

// Libs
import { IntegrationsService } from '../integrations';
import { IAccountIntegration, IAccountIntegrationMetadataType } from 'models';
import { DeviceService } from '../device';
import {
  ObservableDataService,
  QuerySummary,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  ACCOUNT_INTEGRATION_CREATED_IN_SLUG_TOPIC,
  RealtimeServerSocketMessage,
  ACCOUNT_INTEGRATION_UPDATED_IN_SLUG_TOPIC,
  ACCOUNT_INTEGRATION_DELETED_IN_SLUG_TOPIC
} from '../socket';
import { IIntegrationsStoreService } from './integrations-store-interface';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsStoreService implements IIntegrationsStoreService {
  constructor(
    private _device: DeviceService,
    private _integrations: IntegrationsService,
    private _obs: ObservableDataService
  ) {}

  getAccountIntegrations$(
    isLinked = true
  ): Observable<QuerySummary<IAccountIntegration[]>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: ACCOUNT_INTEGRATION_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const integration = IAccountIntegration.fromObject(event?.data);
          return [...currentValue, integration];
        }
      },
      {
        event: ACCOUNT_INTEGRATION_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const updatedIntegration = IAccountIntegration.fromObject(
            event?.data
          );
          const existingIntegrationIndex = currentValue.findIndex(
            (integration) => integration?.id === updatedIntegration?.id
          );

          // if the account integration was linked/unlinked and doesn't match the isLinked filter param, then remove it
          if (
            ((isLinked && !updatedIntegration?.is_linked) ||
              (!isLinked && updatedIntegration?.is_linked)) &&
            existingIntegrationIndex !== -1
          ) {
            currentValue?.splice(existingIntegrationIndex, 1);
            return [...currentValue];
          }

          if (existingIntegrationIndex !== -1) {
            currentValue?.splice(
              existingIntegrationIndex,
              1,
              updatedIntegration
            );
            return [...currentValue];
          }

          if (existingIntegrationIndex == -1 && updatedIntegration?.is_linked) {
            return [...currentValue, updatedIntegration];
          }

          return currentValue;
        }
      },
      {
        event: ACCOUNT_INTEGRATION_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const updatedIntegration = IAccountIntegration.fromObject(
            event?.data
          );
          const existingIntegrationIndex = currentValue.findIndex(
            (integration) => integration?.id === updatedIntegration?.id
          );

          if (existingIntegrationIndex !== -1) {
            currentValue?.splice(existingIntegrationIndex, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._obs.document$<IAccountIntegration[]>({
      handlers,
      lookup: this._integrations.getAccountIntegrations,
      args: isLinked
    });
  }

  getAccountIntegrationsForType$(
    integrationType: IAccountIntegrationMetadataType,
    isLinked = true
  ): Observable<QuerySummary<IAccountIntegration[]>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: ACCOUNT_INTEGRATION_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const integration = IAccountIntegration.fromObject(event?.data);

          // ignore integrations that are not of the type we care about
          if (integration.account_type !== integrationType) {
            return currentValue;
          }

          return [...currentValue, integration];
        }
      },
      {
        event: ACCOUNT_INTEGRATION_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const updatedIntegration = IAccountIntegration.fromObject(
            event?.data
          );

          // ignore integrations that are not of the type we care about
          if (updatedIntegration.account_type !== integrationType) {
            return currentValue;
          }

          const existingIntegrationIndex = currentValue.findIndex(
            (integration) => integration?.id === updatedIntegration?.id
          );

          if (existingIntegrationIndex !== -1) {
            currentValue?.splice(
              existingIntegrationIndex,
              1,
              updatedIntegration
            );
            return [...currentValue];
          }

          return currentValue;
        }
      },
      {
        event: ACCOUNT_INTEGRATION_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAccountIntegration[]
        ) => {
          const updatedIntegration = IAccountIntegration.fromObject(
            event?.data
          );

          // ignore integrations that are not of the type we care about
          if (updatedIntegration.account_type !== integrationType) {
            return currentValue;
          }

          const existingIntegrationIndex = currentValue.findIndex(
            (integration) => integration?.id === updatedIntegration?.id
          );

          if (existingIntegrationIndex !== -1) {
            currentValue?.splice(existingIntegrationIndex, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._obs.document$<IAccountIntegration[]>({
      handlers,
      lookup: this._integrations.getAccountIntegrationsForType,
      args: {
        integrationType,
        isLinked
      }
    });
  }
}
