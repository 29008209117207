import { NotificationMediums } from 'models';

export class ILoginRequestStatus {
  success!: boolean;
  accessToken?: string;
  refreshToken?: string;
  medium!: NotificationMediums | 'none';
}

export type PhoneVerificationPayload = {
  verificationCode: string;
  phoneNumber: string;
  invertMergeFlow?: boolean;
};

export type EmailVerificationPayload = {
  verificationCode: string;
  email: string;
};
