<p class="text-neutral-600">{{ title }}</p>

<form [formGroup]="form" nz-form nzLayout="vertical">
  <nz-form-item *ngIf="step === 'phone'">
    <nz-input-group nzCompact>
      <nz-select
        style="width: 30%"
        (nzOpenChange)="!$event ? numberInput.focus() : null"
        (ngModelChange)="handleCountryCodeChanged($event)"
        formControlName="countryCode"
      >
        <nz-option
          [nzLabel]="country.value?.flag + ' +' + country.value?.value"
          nzCustomContent
          *ngFor="let country of VALID_COUNTRY_CODES | keyvalue: preserveOrder"
          [nzValue]="country.key"
        >
          {{ country.value?.flag + ' ' + country.value?.label }}
        </nz-option>
      </nz-select>
      <input
        style="width: 70%"
        type="tel"
        nz-input
        #numberInput
        [placeholder]="phonePlaceholder"
        formControlName="phoneNumber"
        required
      />
    </nz-input-group>
  </nz-form-item>

  <nz-form-item *ngIf="step === 'code'">
    <nz-input-group [nzSuffix]="resendButton">
      <input
        nz-input
        type="number"
        name="verificationCode"
        formControlName="verificationCode"
        placeholder="Your verification code"
        required
        minLength="6"
        maxLength="6"
      />
    </nz-input-group>

    <ng-template #resendButton>
      <div class="stagger">
        <button
          (click)="handleResendCode()"
          [nzLoading]="isResendingCode"
          nz-button
          nzSize="small"
          type="button"
        >
          <i nz-icon nzType="redo"></i>
          Resend
        </button>
      </div>
    </ng-template>
  </nz-form-item>
</form>
