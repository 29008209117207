import { Injectable } from '@angular/core';

// 3rd party
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  IQueryResult,
  PaginatedQueryFilters,
  PaginatedQuerySummary,
  NlqExampleQuestion,
  INlqExampleQuestionCategoriesListingResults
} from 'models';

// App
import { ObservableDataService, QuerySummary } from '../observable-data';

import { IExampleQuestionsStoreService } from './example-questions-store.service.interface';
import { ExampleQuestionsService } from '../example-questions/example-questions.service';

@Injectable({
  providedIn: 'root'
})
export class ExampleQuestionsStoreService
  implements IExampleQuestionsStoreService
{
  constructor(
    private _obs: ObservableDataService,
    private _exampleQuestions: ExampleQuestionsService
  ) {}

  getCustomerExampleQuestions$(
    args: PaginatedQueryFilters
  ): Observable<PaginatedQuerySummary<NlqExampleQuestion>> {
    const lookup = this._exampleQuestions.getCustomerExampleQuestions;

    const transformer = (
      res: IQueryResult,
      currentValue: NlqExampleQuestion[]
    ) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const conversations =
        res?.edges?.map((edge) => {
          return plainToClass(NlqExampleQuestion, edge?.node);
        }) ?? [];

      return {
        items: [...(currentValue ?? []), ...conversations],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    return this._obs.query$<NlqExampleQuestion>({
      args,
      handlers: [],
      lookup,
      transformer,
      cacheKey: 'getCustomerExampleQuestions$'
    });
  }

  getAllExampleQuestions$(): Observable<
    QuerySummary<INlqExampleQuestionCategoriesListingResults>
  > {
    return this._obs.document$<INlqExampleQuestionCategoriesListingResults>({
      handlers: [],
      lookup: this._exampleQuestions.getAllExampleQuestions,
      isPublic: true,
      cacheKey: 'getAllExampleQuestions$'
    });
  }
}
