import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LoadingIndicatorViewComponent } from '../views/loading-indicator-view';
import { IconModule } from 'uikit';

@NgModule({
  declarations: [LoadingIndicatorViewComponent],
  imports: [CommonModule, IconModule, NzIconModule],
  exports: [LoadingIndicatorViewComponent]
})
export class LoadingIndicatorViewModule {}
