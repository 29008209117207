import { Subscription } from 'rxjs';

import { RealtimeServerSocketMessage, RealtimeSocketMessages } from '../socket';
import {
  IQueryResult,
  PaginatedQueryFilters,
  PaginatedQuerySummary
} from 'models';

export type ObserversChangedCallback = (
  oldCount: number,
  newCount: number
) => void;

// Describes a handler for a given socket event
export type RealtimeSocketEventHandler = {
  event: RealtimeSocketMessages;
  payload: { resourceId: string };
  subscription?: Subscription;
  transformer: (event: RealtimeServerSocketMessage, currentValue: any) => any;
};

export type QueryRealtimeSocketEventHandler = RealtimeSocketEventHandler & {
  transformer: (
    event: RealtimeServerSocketMessage,
    currentValue: PaginatedQuerySummary<any>
  ) => PaginatedQuerySummary<any>;
};

// Describes the input for a given paginated query or document lookup
export type QueryInput = {
  // Socket event handlers
  // Responsible for incorporating socket event payloads
  // into the stream
  handlers?: RealtimeSocketEventHandler[];

  // Refresh / lookup function
  lookup?: (args?: any) => Promise<any>;

  // Arguments to pass to lookup function
  args?: any;

  // Streams are considered private by default and
  // will forcibly be cleared if the user or slug
  // changes
  isPublic?: boolean;

  // Optionally specify a cache key for the stream
  cacheKey?: string;
};

// Describes a real time paginated query
export type PaginatedQueryInput = QueryInput & {
  // Page lookup function
  lookup?: (args: PaginatedQueryFilters) => Promise<IQueryResult>;

  // A function that takes the response for a given page
  // and incorporates it into the stream
  transformer?: (
    result: IQueryResult,
    currentValue: any
  ) => PaginatedQueryTransformerPayload<any>;

  // Filters for the lookup function
  args?: PaginatedQueryFilters;
};

// Describes an updated paginated query result after the
// transformer has incorporated new items
export class PaginatedQueryTransformerPayload<T> {
  items?: T[];
  cursor?: string;
}

// Actual emitted payload handled by the component for a document
export class QuerySummary<T> {
  data?: T;
  next?: (val) => void;
}
