<div class="mb-4">
  <norby-social-links-selector
    (onValueChange)="handleLinkSelectorValueChanged($event)"
    [slug]="slug"
    placeholder="Select an option..."
  >
  </norby-social-links-selector>
</div>

<div
  *ngIf="links?.length > 0"
  #dropList
  cdkDropList
  cdkDropListOrientation="vertical"
  [cdkDropListData]="links"
  (cdkDropListDropped)="handleDrop($event)"
>
  <div
    class="item flex flex-row items-center h-14"
    *ngFor="let item of links; let idx = index"
    cdkDrag
    [cdkDragDisabled]="dragDisabled"
  >
    <div
      *ngIf="!dragDisabled"
      cdkDragHandle
      class="item-handle cursor-grab opacity-50 hover:opacity-100 flex flex-row items-center"
    >
      <root-icon name="more_vertical" class="first-handle"></root-icon>
      <root-icon name="more_vertical" class="second-handle"></root-icon>
    </div>
    <norby-icon
      [name]="item.icon"
      size="small"
      class="flex-none text-neutral-500"
    ></norby-icon>
    <div class="flex flex-col flex-auto px-1 overflow-hidden">
      <span *ngIf="!isEditing(idx)" class="text-xs truncate text-neutral-900">{{
        item.label
      }}</span>
      <span
        *ngIf="item.url !== '' && !isEditing(idx)"
        class="text-xs truncate text-neutral-600"
        >{{ item.url }}</span
      >
      <norby-input
        *ngIf="isEditing(idx)"
        [(ngModel)]="item.url"
        (onBlur)="handleSaveSocialLink(idx)"
        (keydown.enter)="handleSaveSocialLink(idx)"
        [isUrl]="true"
        class="text-xs truncate text-neutral-600"
      ></norby-input>
    </div>
    <div class="flex flex-row flex-auto justify-end">
      <button
        class="item-control"
        *ngIf="!isEditing(idx)"
        (click)="handleEditSocialLink(idx)"
      >
        <norby-icon
          name="edit_2"
          size="small"
          class="flex-none text-neutral-700 hover:text-neutral-900"
        ></norby-icon>
      </button>
      <button class="item-control" (click)="handleRemoveSocialLink(idx)">
        <norby-icon
          name="trash"
          size="small"
          class="flex-none text-neutral-700 hover:text-neutral-900"
        ></norby-icon>
      </button>
    </div>
    <div
      class="item-placeholder-transition h-14 bg-mustard-200 rounded-sm"
      *cdkDragPlaceholder
    ></div>
  </div>
</div>
