import { Injectable } from '@angular/core';

// 3rd party
import { from } from 'rxjs';
import { Socket } from 'socket.io-client';

// App
import { SocketMessage } from './types';
import { DISCONNECTED_STATE } from './constants';
import { ISocketService } from './socket.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SocketServerService implements ISocketService {
  socket: Socket;
  readonly connectionStateChanged$ = from([DISCONNECTED_STATE]);
  readonly realtimeSocketServerMessages$ = from([null]);

  constructor() {}

  sendMessage({ message, payload }: SocketMessage) {}
}
