import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeHtmlPipeModule } from 'uikit';
import { ConversationAttachmentView } from '../views/conversation-attachment-view/conversation-attachment-view.component';
import { HoverDirectiveModule } from './hover-directive.module';

@NgModule({
  declarations: [ConversationAttachmentView],
  imports: [CommonModule, SafeHtmlPipeModule, HoverDirectiveModule],
  exports: [ConversationAttachmentView]
})
export class ConversationAttachmentViewModule {}
