<div class="edit-profile-container">
  <form nz-form [formGroup]="profileFormGroup" nzLayout="vertical">
    <div class="row">
      <div class="left">
        <nz-form-item>
          <nz-form-label>Avatar</nz-form-label>
          <nz-upload
            nzName="banner"
            nzListType="picture-card"
            [nzShowUploadList]="false"
            [nzBeforeUpload]="uploadFileFn"
            [nzAccept]="IMAGE_MIME_TYPES"
          >
            <ng-container *ngIf="!currentImageUrl || isUploading">
              <div class="flex items-center">
                <div [ngClass]="{ 'animate-spin': isUploading }">
                  <root-icon
                    [name]="isUploading ? 'loader' : 'plus'"
                  ></root-icon>
                </div>
                {{ isUploading ? 'Uploading...' : 'Upload' }}
              </div>
            </ng-container>
            <lib-soft-image-view
              initialHeight="80px"
              [imageUrl]="currentImageUrl"
              *ngIf="currentImageUrl && !isUploading"
            >
            </lib-soft-image-view>
          </nz-upload>
        </nz-form-item>
      </div>
      <div class="right">
        <nz-form-item>
          <nz-form-label nzFor="displayName" nzRequired>Name</nz-form-label>
          <input
            type="text"
            class="name-input"
            nzSize="large"
            nz-input
            formControlName="displayName"
            placeholder="Benedict Cumberbatch"
            required
          />
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="email">Email</nz-form-label>
          <nz-form-control
            [nzExtra]="this.userMetadata?.emailVerified ? 'Verified' : ''"
          >
            <input
              type="email"
              nzSize="large"
              class="email-input"
              nz-input
              formControlName="email"
              placeholder="b@cumberbatch.com"
              required
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="!isLoading">
          <nz-form-label nzFor="phoneNumber">Phone number</nz-form-label>
          <nz-form-control
            [nzExtra]="userMetadata?.phoneNumber ? 'Verified' : ''"
          >
            <input
              *ngIf="userMetadata?.phoneNumber"
              [(ngModel)]="userMetadata.phoneNumber"
              [ngModelOptions]="{ standalone: true }"
              [placeholder]="PHONE_PLACEHOLDER"
              class="phone-input"
              disabled
              nz-input
              nzSize="large"
              readonly
              required
              type="text"
            />
            <button
              *ngIf="!userMetadata?.phoneNumber"
              (click)="handleAddPhoneNumber()"
              nz-button
            >
              Add phone number
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  <div class="error" *ngIf="error">
    <p>{{ error }}</p>
  </div>
</div>
