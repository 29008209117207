import { Injectable } from '@angular/core';

// Libs
import {
  ENDPOINTS,
  ApiSurfaces,
  INlqExampleQuestionCategoriesListingResults,
  INlqExampleQuestionListingResults
} from 'models';
import { ErrorService } from 'uikit';
import { IExampleQuestionsService } from './example-questions.service.interface';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class ExampleQuestionsService implements IExampleQuestionsService {
  constructor(
    private _api: ApiService,
    private _error: ErrorService
  ) {}

  getCustomerExampleQuestions =
    async (): Promise<INlqExampleQuestionListingResults> => {
      try {
        const ret = await this._api.get<INlqExampleQuestionListingResults>(
          ApiSurfaces.API,
          `${ENDPOINTS.ai.exampleQuestions}`
        );
        return ret;
      } catch (e) {
        this._error.displayError(e);
      }
    };

  getAllExampleQuestions =
    async (): Promise<INlqExampleQuestionCategoriesListingResults> => {
      try {
        const ret =
          await this._api.get<INlqExampleQuestionCategoriesListingResults>(
            ApiSurfaces.API,
            `${ENDPOINTS.ai.categoryQuestions}`
          );
        return ret;
      } catch (e) {
        this._error.displayError(e);
      }
    };

  getExampleQuestionsInCategory = async (
    category: string
  ): Promise<INlqExampleQuestionListingResults> => {
    try {
      const ret = await this._api.get<INlqExampleQuestionListingResults>(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.categoryQuestions}/${category}`
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };
}
