import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// App
import { IContactsListStoreService } from './contacts-list-store.service.interface';

// Lib
import {
  ContactList,
  ContactListListSummaryResponse,
  ContactView,
  PaginatedQuerySummary
} from 'models';
import { QuerySummary } from '../observable-data';

@Injectable({
  providedIn: 'root'
})
export class ContactsListStoreServerService
  implements IContactsListStoreService
{
  constructor() {}

  getContactListSummary$(): Observable<
    QuerySummary<ContactListListSummaryResponse>
  > {
    return from([null]);
  }

  getContactLists$(): Observable<PaginatedQuerySummary<ContactList>> {
    return from([null]);
  }

  getContactList$(listId: string): Observable<QuerySummary<ContactView>> {
    return from([null]);
  }
}
