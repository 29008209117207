import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Lib
import { DEFAULT_ACCOUNT_STATUS, IAccountStoreService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class AccountStoreServerService implements IAccountStoreService {
  private _addOns$ = new BehaviorSubject(null);
  private _accountStatus$ = new BehaviorSubject(DEFAULT_ACCOUNT_STATUS);

  accountStatus$ = this._accountStatus$.asObservable();
  addOns$ = this._addOns$.asObservable();
}
