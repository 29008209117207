import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SuperAdminService } from '@super-admin/services';

@Injectable({
  providedIn: 'root'
})
export class SuperEditorGuard {
  constructor(private _superAdmin: SuperAdminService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this._superAdmin.userIsGlobalEditor$().pipe(take(1));
  }
}

@Injectable({
  providedIn: 'root'
})
export class SuperDeveloperGuard {
  constructor(private _superAdmin: SuperAdminService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this._superAdmin.userIsGlobalDeveloper$().pipe(take(1));
  }
}
