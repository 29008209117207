import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// Libs
import {
  PaginatedQueryFilters,
  PaginatedQuerySummary,
  INlqExampleQuestionCategoriesListingResults,
  NlqExampleQuestion
} from 'models';
import { IExampleQuestionsStoreService } from './example-questions-store.service.interface';
import { QuerySummary } from '../observable-data';

@Injectable({
  providedIn: 'root'
})
export class ExampleQuestionsStoreServerService
  implements IExampleQuestionsStoreService
{
  getCustomerExampleQuestions$(
    args: PaginatedQueryFilters
  ): Observable<PaginatedQuerySummary<NlqExampleQuestion>> {
    return from([null]);
  }

  getAllExampleQuestions$(): Observable<
    QuerySummary<INlqExampleQuestionCategoriesListingResults>
  > {
    return from([null]);
  }
}
