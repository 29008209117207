import { Injectable } from '@angular/core';

// 3rd party
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiServerService {
  globalIsLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setLoading(loading = false) {}
}
