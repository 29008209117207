import { Injectable } from '@angular/core';

// 3rd party
import { from, Observable } from 'rxjs';

// Libs
import {
  IntegrationType,
  ISlug,
  ISlugGeneralSettings,
  IUserRole,
  RolesEnum
} from 'models';
import { ISlugManagementService } from './slug-management.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SlugManagementServerService implements ISlugManagementService {
  constructor() {}

  async getTeamMemberEmails() {
    return [];
  }

  async setCurrentSlug(
    slug: Partial<ISlug>,
    merge = true,
    showConfirmation = true
  ) {}

  // Invite a user by uid to the given slug/organization
  async inviteUserToOrganization(input: {
    role: RolesEnum;
    userIdentifier: string;
    slug: string;
    onboarding?: boolean;
    expiresInMinutes?: number;
  }) {}

  // Remove a given member by uid from the given organization
  async removeUserFromOrganization(input: { slug?: string; userId: string }) {}

  // Update a user's role in the given organization
  async updateRoleInOrganization(
    input: { slug?: string; userId: string },
    newRole: string
  ) {}

  // Update the reply to address for the org
  async setAccountSettings(
    update: ISlugGeneralSettings,
    showConfirmation = true
  ) {}

  // Reset the current organizations Norby API key
  async resetApiKey() {}

  // Unlink the given integration from the given organization
  async unlinkIntegration(input: {
    integration: IntegrationType;
    slug: string;
  }): Promise<boolean> {
    return Promise.resolve(false);
  }

  // Direct Firestore query for organization members
  getOrganizationMembersCollection(cursor?: any) {
    return from([null]);
  }

  getOrganizationMembers$(
    accessType: 'temporary' | 'permanent' | 'all',
    cursor?: any
  ): Observable<IUserRole[]> {
    return from([null]);
  }

  async transferOwnership(newOwnerUserId: string) {}
}
