<div class="contact-table-container">
  <nz-table
    (nzPageIndexChange)="handlePageChange($event)"
    (nzPageSizeChange)="handlePageSizeChange($event)"
    [(nzPageIndex)]="currentPage"
    [nzData]="contacts"
    [nzFrontPagination]="false"
    [nzLoading]="isLoading"
    [nzPageSizeOptions]="[10, 25, 50]"
    [nzPageSize]="pageSize"
    [nzShowSizeChanger]="true"
    [nzTotal]="totalItems"
    [nzPaginationType]="isMobile ? 'small' : 'default'"
    [nzScroll]="isMobile ? { x: '900' } : null"
  >
    <thead>
      <tr>
        <th nzLeft>Name</th>
        <th nzEllipsis>Email</th>
        <th nzEllipsis>Phone</th>
        <th nzEllipsis>Tags</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let contact of contacts; trackBy: trackBy; let i = index"
        class="contact-row"
        (click)="showContactDetails(contact, i)"
      >
        <td nzLeft>
          <lib-user-row-view
            avatarSize="small"
            [userId]="contact?.id"
            [displayName]="contact?.name"
            [photoURL]="contact?.avatarUrl"
          >
          </lib-user-row-view>
        </td>

        <td nzEllipsis>
          <span class="email-row"
            >{{ contact?.primary_email }}
            <root-icon
              *ngIf="contact?.primary_email_verified"
              name="check_circle"
              nz-tooltip
              nzTooltipTitle="Verified"
            ></root-icon
          ></span>
        </td>

        <td nzEllipsis>{{ contact?.primary_phone_number | phone }}</td>

        <td nzEllipsis>
          <nz-tag *ngFor="let tag of contact?.tags">{{ tag }}</nz-tag>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
