import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventMainButtonComponent } from '../components/event-main-button';
import { EventMainButtonViewModule } from 'uikit';

@NgModule({
  declarations: [EventMainButtonComponent],
  imports: [CommonModule, EventMainButtonViewModule],
  exports: [EventMainButtonComponent]
})
export class EventMainButtonModule {}
