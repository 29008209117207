import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';

import {
  NorbyButtonModule,
  NorbyCollapsibleGroupModule,
  NorbyCollapsibleModule,
  NorbyFileUploadModule,
  NorbyFormcontrolLabelModule,
  NorbyIconRadioButtonGroupModule,
  NorbyIconRadioButtonModule,
  NorbyInputModule,
  NorbySliderModule,
  NorbySwitchButtonModule,
  NorbyTextAreaModule
} from 'uikit';

import {
  EditEmailButtonBlockComponent,
  EditEmailDividerBlockComponent,
  EditEmailHeaderBlockComponent,
  EditEmailImageBlockComponent,
  EditEmailSpacerBlockComponent,
  EditEmailTextBlockComponent,
  EditEmailFooterBlockComponent,
  EditEmailYoutubeBlockComponent,
  EditEmailEventBlockComponent,
  EditEmailQuoteBlockComponent,
  EditEmailSignupBlockComponent,
  EditEmailUpcomingEventsBlockComponent,
  EditNlqPromptBlockComponent,
  EditNlqSummaryBlockComponent
} from '../components/templates/email-block-editors';
import { NorbyDropShadowPickerModule } from './norby-drop-shadow-picker.module';
import { NorbyEditSocialIconsModule } from './norby-edit-social-icons.module';
import { NorbyContentSelectorModule } from './norby-content-selector.module';
import { NorbyAntColorPickerModule } from './norby-ant-color-picker.module';

@NgModule({
  declarations: [
    EditEmailHeaderBlockComponent,
    EditEmailImageBlockComponent,
    EditEmailTextBlockComponent,
    EditEmailSpacerBlockComponent,
    EditEmailDividerBlockComponent,
    EditEmailButtonBlockComponent,
    EditEmailFooterBlockComponent,
    EditEmailYoutubeBlockComponent,
    EditEmailEventBlockComponent,
    EditEmailQuoteBlockComponent,
    EditEmailSignupBlockComponent,
    EditEmailUpcomingEventsBlockComponent,
    EditNlqPromptBlockComponent,
    EditNlqSummaryBlockComponent
  ],
  imports: [
    CommonModule,
    NorbyInputModule,
    NzInputModule,
    NzFormModule,
    NzDividerModule,
    NzButtonModule,
    NzSegmentedModule,
    TextFieldModule,
    NorbyCollapsibleGroupModule,
    NorbyCollapsibleModule,
    ReactiveFormsModule,
    NorbyFileUploadModule,
    NorbyIconRadioButtonGroupModule,
    NorbyIconRadioButtonModule,
    NorbySliderModule,
    NorbyDropShadowPickerModule,
    NorbyEditSocialIconsModule,
    NorbyContentSelectorModule,
    NorbyButtonModule,
    NorbyFormcontrolLabelModule,
    NorbyTextAreaModule,
    NorbySwitchButtonModule,
    NorbyAntColorPickerModule
  ],
  exports: [
    EditEmailHeaderBlockComponent,
    EditEmailImageBlockComponent,
    EditEmailTextBlockComponent,
    EditEmailSpacerBlockComponent,
    EditEmailDividerBlockComponent,
    EditEmailButtonBlockComponent,
    EditEmailFooterBlockComponent,
    EditEmailYoutubeBlockComponent,
    EditEmailEventBlockComponent,
    EditEmailQuoteBlockComponent,
    EditEmailSignupBlockComponent,
    EditEmailUpcomingEventsBlockComponent,
    EditNlqPromptBlockComponent,
    EditNlqSummaryBlockComponent
  ]
})
export class EmailBlockEditorsModule {}
