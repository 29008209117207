<nz-segmented
  [nzOptions]="TABS"
  (nzValueChange)="handleTabChanged($event)"
></nz-segmented>

<div class="mt-4">
  <form [formGroup]="formGroup" nzLayout="vertical" nz-form>
    <ng-container *ngIf="currentTab === 'Settings'">
      <nz-form-item>
        <nz-form-label>Prompt</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            [nzAutosize]="{ minRows: 4, maxRows: 8 }"
            formControlName="summarizationInstructions"
            placeholder="What was..."
          ></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Reports</nz-form-label>

        <ng-container formGroupName="prompts">
          <norby-collapsible-group>
            @for (
              control of promptsControls;
              track control.controlInstance;
              let i = $index
            ) {
              <norby-collapsible
                [title]="getTitleForControl(control)"
                [formGroupName]="control.controlInstance"
              >
                <nz-form-label>Report</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <textarea
                      nz-input
                      [nzAutosize]="{ minRows: 3, maxRows: 8 }"
                      formControlName="prompt"
                      placeholder="What was..."
                    ></textarea>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-label>Additional instructions</nz-form-label>
                <nz-form-item>
                  <nz-form-control>
                    <textarea
                      nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 8 }"
                      formControlName="additionalInstructions"
                      placeholder="Any other instructions"
                    ></textarea>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <button
                    nz-button
                    nzType="dashed"
                    nzDanger
                    nzBlock
                    (click)="handleRemoveField(control, $event)"
                  >
                    Remove
                  </button>
                </nz-form-item>
              </norby-collapsible>
            }
          </norby-collapsible-group>
        </ng-container>
      </nz-form-item>

      <nz-form-item>
        <button
          nz-button
          nzType="dashed"
          nzBlock
          (click)="handleAddField($event)"
        >
          Add a report
        </button>
      </nz-form-item>
    </ng-container>

    <ng-container *ngIf="currentTab === 'Styles'">
      <norby-input
        label="Top padding"
        type="number"
        formControlName="paddingTop"
      ></norby-input>
      <norby-input
        label="Right padding"
        type="number"
        formControlName="paddingRight"
      ></norby-input>
      <norby-input
        label="Bottom padding"
        type="number"
        formControlName="paddingBottom"
      ></norby-input>
      <norby-input
        label="Left padding"
        type="number"
        formControlName="paddingLeft"
      ></norby-input>
    </ng-container>
  </form>
</div>
