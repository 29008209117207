import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventDropShareButtonComponent } from '../components/event-drop-share-button';
import { EventDropShareButtonViewModule } from 'uikit';

@NgModule({
  declarations: [EventDropShareButtonComponent],
  imports: [CommonModule, EventDropShareButtonViewModule],
  exports: [EventDropShareButtonComponent]
})
export class EventDropShareButtonModule {}
