export const environment = {
  production: false,
  zapierUrl: 'https://zapier.com/apps/norby/integrations',
  cloudFunctionsBaseUrl: 'https://api.exp.norby.live',
  analyticsUrl: 'https://analytics.services.exp.norby.live/cubejs-api/v1',
  embedsUrl: 'https://embeds.exp.norby.live/exp.js',
  stripeClientKey:
    'pk_test_51Grr4AEkL53ABPMP0UtX160mcu0VXJhytvUHdnFueXhVuZbTljLuFCEHnBAFvM7rNiuD9TEZpxKVpuaAulXaVyJM00G3nYBMC5',
  rootHost: 'exp.nor.by',
  cookiePrefix: 'norby-exp-',
  firebaseConfig: {
    apiKey: 'AIzaSyDV2yxoaAxqDH1pzYiLXr3bBCLf2h_0F-s',
    authDomain: 'magic-sauce-experimental.firebaseapp.com',
    databaseURL: 'https://magic-sauce-experimental.firebaseio.com',
    projectId: 'magic-sauce-experimental',
    storageBucket: 'magic-sauce-experimental.appspot.com',
    messagingSenderId: '897972596861',
    appId: '1:897972596861:web:4bc6f0021bf867690ec114'
  },
  apiUrl: 'https://api.services.exp.norby.live',
  authUrl: 'https://auth.services.exp.norby.live',
  corsAnywhereUrl: 'https://cors-proxy.services.norby.live',
  fontBucket: 'magic-sauce-experimental-fonts',
  mmsAttachmentBucket: 'magic-sauce-experimental-mms-attachments',
  socketServerUrl: 'wss://socket.services.exp.norby.live',
  recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  endUserUrl: 'https://end-user.services.exp.norby.live',
  analyticsApiUrl: 'https://analytics-worker.services.exp.norby.live',
  adminNlqUrl: 'https://admin-nlq.services.exp.norby.live'
};
