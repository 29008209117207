import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-conversation-attachment-view',
  templateUrl: './conversation-attachment-view.component.html',
  styleUrls: ['./conversation-attachment-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationAttachmentView {
  @Input() attachmentHtml: string;
}
