import {
  SendContentVariableTypeEnum,
  SendCustomVariableTypeEnum,
  SendUserVariableTypeEnum,
  SendVariable,
  isBrowser
} from 'models';

import { default as Mustache } from 'mustache';

export const convertDeltaOpsToMustachePlainText = (
  ops: Array<{ insert: any }>
) => {
  return ops.reduce((acc, op, index) => {
    if (typeof op.insert === 'string') {
      if (index === ops.length - 1) {
        return acc + op.insert.replace(/\n$/g, '');
      }
      return acc + op.insert;
    } else if (op.insert?.variablePill) {
      const mustachePill = convertSinglePillToMustache(op.insert.variablePill);

      return acc + mustachePill;
    }

    return acc;
  }, '');
};

export const convertNewLinesToHtml = (initialValue: string) => {
  return initialValue?.replace(/\n/g, '<br>');
};

export const convertPillsToMustache = (text: string) => {
  if (!isBrowser) {
    return;
  }

  let bodyElement = document.createElement('div');
  bodyElement.innerHTML = text;
  let variables = bodyElement.getElementsByClassName('variable-pill');

  const mustaches = [];
  for (let i = 0; i < variables?.length; i++) {
    const type = variables[i].getAttribute('data-type');
    const defaultValue = variables[i].getAttribute('data-value');
    const contentId = variables[i].getAttribute('data-content-id');
    const relativeDateText = variables[i].getAttribute(
      'data-relative-date-text'
    );
    const relativeDateFormat = variables[i].getAttribute(
      'data-relative-date-format'
    );
    let mustache = '';

    if (
      type === SendCustomVariableTypeEnum.RELATIVE_DATE &&
      relativeDateText &&
      relativeDateFormat
    ) {
      mustache = `{{{RELATIVE_DATE-(${relativeDateText})-(${relativeDateFormat})}}}`;
      mustaches.push(mustache);
    } else if (SendUserVariableTypeEnum[type]) {
      mustache = `{{${type}}}`;
      if (defaultValue) {
        mustache += `{{^${type}}}${defaultValue}{{/${type}}}`;
      }
      mustaches.push(mustache);
    } else if (SendContentVariableTypeEnum[type]) {
      mustache = contentId ? `{{CONTENT_${contentId}_${type}}}` : `{{${type}}}`;
      mustaches.push(mustache);
    }
  }

  const matchFirstPill = /<span\b[^>]*>(.*?)<\/span>/;
  let ret = text;
  mustaches.forEach((mustache) => {
    const partial = ret.replace(matchFirstPill, '<span>');
    ret = partial.replace(matchFirstPill, mustache);
  });

  return ret;
};

export const convertSinglePillToMustache = (variable: SendVariable) => {
  if (!isBrowser || !variable) {
    return;
  }

  let mustache = '';
  if (
    variable.type === SendCustomVariableTypeEnum.RELATIVE_DATE &&
    variable.relativeDateText &&
    variable.relativeDateFormat
  ) {
    mustache = `{{{RELATIVE_DATE-(${variable.relativeDateText})-(${variable.relativeDateFormat})}}}`;
  } else if (SendUserVariableTypeEnum[variable.type]) {
    mustache = `{{${variable.type}}}`;
    if (variable.defaultValue) {
      mustache += `{{^${variable.type}}}${variable.defaultValue}{{/${variable.type}}}`;
    }
  } else if (SendContentVariableTypeEnum[variable.type]) {
    mustache = variable.contentId
      ? `{{CONTENT_${variable.contentId}_${variable.type}}}`
      : `{{${variable.type}}}`;
  }

  return mustache;
};

export const convertMustacheToPills = (text: string) => {
  if (!text || !isBrowser) {
    return;
  }

  let response = text;
  const parsedText = Mustache.parse(response);
  const hasDefaultValueMatcher = /{{([^{}]+)}}{{\^(\1)}}(.*){{\/\1}}/;
  const variableMatcher = /{{.*?}}/;
  const relativeDateValueMatcher = /RELATIVE_DATE-\(([^)]+)\)-\(([^)]+)\)/;
  const relativeDateVariableMatcher =
    /{{{RELATIVE_DATE-\(([^)]+)\)-\(([^)]+)\)}}}/;

  for (let i = 0; i < parsedText?.length; i++) {
    const type = parsedText[i][1];
    let defaultValue = null;
    let contentId = null;
    let strippedType = null;
    const hasContentId = type.match(/_/g)?.length > 2;

    if (type.startsWith('RELATIVE_DATE')) {
      const matches = type.match(relativeDateValueMatcher);

      if (matches) {
        const relativeDateText = matches[1];
        const relativeDateFormat = matches[2];
        const node = document.createElement('span');
        node.setAttribute(
          'data-type',
          SendCustomVariableTypeEnum.RELATIVE_DATE
        );
        node.setAttribute('class', 'variable-pill');
        node.setAttribute('data-relative-date-text', relativeDateText);
        node.setAttribute('data-relative-date-format', relativeDateFormat);

        const wrapper = document.createElement('DIV');
        wrapper.appendChild(node);

        response = response.replace(
          relativeDateVariableMatcher,
          wrapper.innerHTML
        );
      }
      continue;
    }

    if (parsedText[i + 1]?.[0] === '^') {
      defaultValue = parsedText[i + 1][4][0][1];
    }
    if (type.startsWith('CONTENT_') && hasContentId) {
      contentId = getContentIDFromContentTemplateVariable(type);

      const regex = /(?:[^_\n]+_){2}(.*)$/;
      strippedType = type.match(regex)[1];
    }
    if (parsedText[i]?.[0] === 'name') {
      const mustacheType = hasContentId ? strippedType : type;
      const node = document.createElement('span');

      node.setAttribute('data-type', mustacheType);
      node.setAttribute('class', 'variable-pill');
      if (defaultValue) {
        node.setAttribute('data-value', defaultValue);
      }
      if (contentId) {
        node.setAttribute('data-content-id', contentId);
      }

      const wrapper = document.createElement('DIV');
      wrapper.appendChild(node);

      response = response.replace(
        defaultValue ? hasDefaultValueMatcher : variableMatcher,
        wrapper.innerHTML
      );
    }
  }
  return response;
};

const getContentIDFromContentTemplateVariable = (
  contentTemplateVariable: string
) => {
  const split = contentTemplateVariable.split('_');
  const contentId = split[1];

  return contentId;
};

export const createCaret = () => {
  if (!isBrowser) {
    return;
  }

  const caret = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  caret.setAttribute('width', '24');
  caret.setAttribute('height', '24');
  caret.setAttribute('fill', 'none');
  caret.setAttribute('stroke', 'currentColor');
  caret.setAttribute('stroke-width', '2');
  caret.setAttribute('stroke-linecap', 'round');
  caret.setAttribute('stroke-linejoin', 'round');
  caret.setAttribute('class', 'feather feather-chevron-down');

  path.setAttribute('d', 'm6 9 6 6 6-6');
  caret.appendChild(path);
  return caret;
};
