import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// Libs
import { isBrowser } from 'models';
import { BaseComponent } from 'uikit';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class InfiniteScrollV3Component
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _elementRef = inject(ElementRef);
  private _intersectionObserver: IntersectionObserver;
  private _trackingPixel: HTMLDivElement;
  private _previousScrollHeightMinusTop = 0;
  private _scrollParent: HTMLElement;
  private _scroll$ = new Subject<void>();
  private _loading$ = new BehaviorSubject<boolean>(false);

  abstract get fetchFn(): () => Promise<void>;

  get isLoadingNextPage(): boolean {
    return this._loading$.value;
  }

  get isLoadingNextPage$() {
    return this._loading$.asObservable();
  }

  get targetScrollContainerElement() {
    return this._scrollParent;
  }

  get shouldRestorePositionAfterPageLoad() {
    return false;
  }

  get targetScrollContentElement() {
    return this._elementRef;
  }

  ngOnInit() {
    this._scroll$
      .pipe(this.takeUntilDestroy)
      .subscribe(() => this.loadNextPage());
  }

  ngAfterViewInit(): void {
    if (!isBrowser || !this.targetScrollContentElement) {
      return;
    }

    this._scrollParent = scrollParent(
      this.targetScrollContentElement.nativeElement
    );

    // Add a tracking pixel to to the bottom of host element
    this._trackingPixel = document.createElement('div');
    this._trackingPixel.style.height = '1px';
    this.targetScrollContentElement.nativeElement.appendChild(
      this._trackingPixel
    );

    // Subscribe for when that pixel becomes visible
    this._intersectionObserver = new IntersectionObserver(
      ([entry]) => entry.isIntersecting && this._scroll$.next()
    );
    this._intersectionObserver.observe(this._trackingPixel);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._intersectionObserver?.disconnect();
  }

  resetScroll() {
    if (this.targetScrollContainerElement) {
      this.targetScrollContainerElement.scrollTop = 0;
    }
  }

  async loadNextPage() {
    if (
      this.isLoadingNextPage ||
      !this.fetchFn ||
      !this.targetScrollContentElement
    ) {
      return;
    }

    this._previousScrollHeightMinusTop = this._scrollParent?.scrollTop ?? 0;
    this._loading$.next(true);

    try {
      await this.fetchFn();
    } catch (e) {
      console.log('Error fetching', e);
    }

    this._loading$.next(false);

    if (this._scrollParent && this.shouldRestorePositionAfterPageLoad) {
      this._scrollParent.scrollTop = this._previousScrollHeightMinusTop;
    }
  }
}

function isScrolling(node) {
  const overflow = getComputedStyle(node, null).getPropertyValue('overflow');
  return overflow.indexOf('scroll') > -1 || overflow.indexOf('auto') > -1;
}

function scrollParent(node): HTMLElement {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return undefined;
  }

  let current = node.parentNode;
  while (current.parentNode) {
    if (isScrolling(current)) {
      return current as HTMLElement;
    }

    current = current.parentNode;
  }

  return (document.scrollingElement || document.documentElement) as HTMLElement;
}
