import { Injectable } from '@angular/core';
import { ITeamModalService } from './team-modal.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TeamModalServerService implements ITeamModalService {
  async launchInviteUser(): Promise<boolean> {
    return false;
  }
}
