import { Injectable } from '@angular/core';

// Libs
import {
  CloneContactListDto,
  ContactListContactResults,
  ContactListListSummaryResponse,
  ContactListResults,
  ContactView,
  CreateContactListDto,
  GetContactListContactsDto,
  IPSQLContactResults,
  PaginatedQueryFilters,
  SetContactListSettingsDto,
  UpdateContactListDto
} from 'models';

// App
import { IContactsService } from './contacts.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactsServerService implements IContactsService {
  constructor() {}

  async getContacts(input: {
    offset?: number;
    after?: string;
    limit?: number;
    lite?: boolean;
    requireMedium?: boolean;
    medium?: 'sms' | 'email' | 'instagram';
  }): Promise<IPSQLContactResults> {
    return null;
  }

  async getContactsCsvUrl() {
    return '';
  }

  async postContactsCsvV2(
    currentSlug: string,
    uploadedFileName: string,
    newsletterOptIn: boolean,
    captchaResponse: string
  ) {}

  async downloadContactsCsv() {}

  getContactListSummary = async (): Promise<ContactListListSummaryResponse> =>
    null;

  updateContactListSettings = async (
    input: SetContactListSettingsDto
  ): Promise<ContactListListSummaryResponse> => null;

  createContactList = async (
    input: CreateContactListDto
  ): Promise<ContactView> => null;

  updateContactList = async (
    listId: string,
    input: UpdateContactListDto
  ): Promise<ContactView> => null;

  cloneContactList = async (
    listId: string,
    input: CloneContactListDto
  ): Promise<ContactView> => null;

  deleteContactList = async ({
    listId
  }: {
    listId: string;
  }): Promise<boolean> => null;

  getContactLists = async (
    input: PaginatedQueryFilters
  ): Promise<ContactListResults> => null;

  getContactList = async ({
    listId
  }: {
    listId: string;
  }): Promise<ContactView> => null;

  getContactsInList = async (
    listId: string,
    input: GetContactListContactsDto
  ): Promise<ContactListContactResults> => ({
    pageInfo: null,
    edges: new Array(10).fill({ node: null })
  });
}
