import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { IUserContent, IUserPublicMetadata, IUserMetadata } from 'models';

@Injectable({
  providedIn: 'root'
})
export class UserServerService {
  async markTipSeen(identifier: string): Promise<void> {}

  async checkTipSeen(identifier: string): Promise<boolean> {
    return true;
  }

  userContentForContent(userId: string, contentId: string) {
    return null;
  }

  getUserContent$(contentId: string): Observable<IUserContent> {
    return from([null]);
  }

  async currentUserMetadata(): Promise<IUserMetadata> {
    return null;
  }

  currentUserMetadata$(): Observable<IUserMetadata> {
    return from([null]);
  }

  async currentUserProfile(): Promise<IUserPublicMetadata> {
    return null;
  }

  currentUserProfile$(): Observable<IUserPublicMetadata> {
    return from([null]);
  }

  async setUserProfile(user: IUserPublicMetadata) {}

  sendBeacon({ data, linkId }: { data?: any; linkId?: string }): boolean {
    return false;
  }
}
