import { Component, OnInit } from '@angular/core';

// 3rd party
import { plainToClass } from 'class-transformer';

// Libs
import { IUserRole } from 'models';
import { IconService, rootChevronDown, rootRotateCw, rootTrash } from 'uikit';

import { InfiniteScrollComponent } from '../../models';
import { PermissionsService, SlugManagementService } from '../../services';

@Component({
  selector: 'lib-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.less']
})
export class UserListComponent
  extends InfiniteScrollComponent
  implements OnInit
{
  userIsAdmin = false;
  userOwnerOfCurrentSite = false;
  isDoingUserAction = false;

  constructor(
    private _permissions: PermissionsService,
    private _slugManagement: SlugManagementService,
    private _iconService: IconService
  ) {
    super();
    this._iconService.registerIcons([rootChevronDown, rootRotateCw, rootTrash]);
  }

  get filteredItems() {
    return (this.items || []).filter((item) => !item?.accessExpiresInMinutes);
  }

  get placeholderCount() {
    return 1;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._permissions
      .userIsAdmin$()
      .pipe(this.takeUntilDestroy)
      .subscribe((isAdmin) => (this.userIsAdmin = isAdmin));

    this._permissions
      .userIsAdmin$(true)
      .pipe(this.takeUntilDestroy)
      .subscribe((isOwner) => (this.userOwnerOfCurrentSite = isOwner));

    this.resetInfiniteScroll();
  }

  trackBy(idx: number, item: IUserRole) {
    return item?.createdAtCursor;
  }

  itemMapper(item) {
    return plainToClass(IUserRole, item);
  }

  get cursorField(): string {
    return 'createdAtCursor';
  }

  documentQuery(startAfter?: string) {
    return this._slugManagement.getOrganizationMembersCollection(startAfter);
  }

  async reinviteUser(user: IUserRole) {
    this.isDoingUserAction = true;
    await this._slugManagement.inviteUserToOrganization({
      role: user.role,
      slug: user.slug,
      userIdentifier: user.email
    });
    this.isDoingUserAction = false;
  }

  async removeUser(user: IUserRole) {
    this.isDoingUserAction = true;
    await this._slugManagement.removeUserFromOrganization({
      userId: user.userId,
      slug: user.slug
    });
    this.isDoingUserAction = false;
  }

  async transferOwnership(user: IUserRole) {
    this.isDoingUserAction = true;
    await this._slugManagement.transferOwnership(user.userId);
    this.isDoingUserAction = false;
  }
}
