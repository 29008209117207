<p>Give your custom style a name.</p>

<form nz-form nzLayout="vertical" [formGroup]="form">
  <nz-form-item>
    <input
      formControlName="name"
      nzSize="large"
      name="name"
      nz-input
      placeholder="Custom style name"
    />
  </nz-form-item>
</form>
