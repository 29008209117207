import { Injectable } from '@angular/core';
import {
  Content,
  ContentEvent,
  ContentRegisterable,
  IShareService,
  IUserContent,
  LandingPage
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class ShareServerService implements IShareService {
  constructor() {}

  get canShare(): boolean {
    return false;
  }
  async share({
    title,
    text,
    url
  }: {
    title: string;
    text?: string;
    url?: string;
  }): Promise<{ shared: boolean; error?: string }> {
    return {
      shared: false,
      error: 'Sharing not supported in server environment.'
    };
  }
  saveToICal(event: ContentEvent) {}
  saveToGoogleCalendar(event: ContentEvent) {}
  openContentShareSheet(content: Content, userContent?: IUserContent) {}
  getUrlForLandingPage(page: LandingPage) {}
  getEmbedCodeForLandingPage(page: LandingPage) {}
  embedStringForType(variant: string, content: ContentRegisterable) {}
}
