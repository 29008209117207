import { Injectable } from '@angular/core';

// Libs
import {
  CreateSingleSendDTO,
  CreateSingleSendResponseDTO,
  SegmentPreviewDTO,
  SegmentPreviewResponseDTO,
  SingleSend,
  SingleSendSummary,
  SingleSendTestMessageDTO
} from 'models';

// App
import { ISendsService } from './sends.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SendsServerService implements ISendsService {
  constructor() {}

  getSendById = async ({ id }: { id: string }): Promise<SingleSend> => {
    return null;
  };

  unscheduleSend = async (id: string): Promise<void> => {
    return null;
  };

  deleteSend = async (id: string): Promise<void> => {
    return null;
  };

  getPreview = async (
    body: SegmentPreviewDTO
  ): Promise<SegmentPreviewResponseDTO> => {
    return null;
  };

  editSend = async (
    id: string,
    body: CreateSingleSendDTO
  ): Promise<CreateSingleSendResponseDTO> => {
    return null;
  };

  createSend = async (
    body: CreateSingleSendDTO
  ): Promise<CreateSingleSendResponseDTO> => {
    return null;
  };

  sendTestMessage = async (body: SingleSendTestMessageDTO): Promise<void> => {
    return null;
  };

  getSingleSendSummaryV2 = async (): Promise<SingleSendSummary> => {
    return null;
  };
}
