import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

// Libs
import { ConversationMessage } from 'models';

@Component({
  selector: 'app-conversation-message-view',
  templateUrl: './conversation-message-view.component.html',
  styleUrls: ['./conversation-message-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationMessageView {
  @Input() message: ConversationMessage;
  @Input() showMetadata = true;
  @Output() onRateMessageClick = new EventEmitter<number>();
  @Output() onExplainAnswerClick = new EventEmitter<void>();
  @Output() onShareClick = new EventEmitter<void>();

  handleThumbsDownClick(): void {
    this.onRateMessageClick.emit(1);
  }

  handleThumbsUpClick(): void {
    this.onRateMessageClick.emit(5);
  }

  handleExplainAnswerClick(): void {
    this.onExplainAnswerClick.emit();
  }

  handleShareClick(): void {
    this.onShareClick.emit();
  }
}
