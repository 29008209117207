import { Injectable } from '@angular/core';
import { CaptureContext } from './types';

@Injectable({
  providedIn: 'root'
})
export class LoggerServerService {
  constructor() {}

  logError(error: Error | string, context?: CaptureContext): void {
    // No op
  }
}
