import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { IPermissionsService } from './permissions.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PermissionsServerService implements IPermissionsService {
  constructor() {}

  async logout(): Promise<void> {}

  userIsGlobalAdmin$(): Observable<boolean> {
    return from([false]);
  }

  userIsGlobalEditor$(): Observable<boolean> {
    return from([false]);
  }

  async userIsGlobalAdmin(): Promise<boolean> {
    return false;
  }

  async userIsGlobalEditor(): Promise<boolean> {
    return false;
  }

  async userIsGlobalDeveloper(): Promise<boolean> {
    return false;
  }

  async userCanViewDashboard(): Promise<boolean> {
    return true;
  }

  userCanEditSite$(): Observable<boolean> {
    return from([false]);
  }

  async userCanEditSite(): Promise<boolean> {
    return false;
  }

  userIsAdmin$(requireOwner = false): Observable<boolean> {
    return from([false]);
  }

  isUserOwnerOfCurrentSite$(): Observable<boolean> {
    return from([false]);
  }
}
