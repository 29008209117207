import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'lib-custom-style-item',
  templateUrl: './custom-style-item.component.html',
  styleUrls: ['./custom-style-item.component.less']
})
export class CustomStyleItemComponent extends BaseComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: NzModalRef<CustomStyleItemComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      name: [undefined, [Validators.required]]
    });

    this._dialogRef.updateConfig({
      nzOnOk: () => {
        this._dialogRef.close({ ...this.form.value });
      }
    });

    this.form.valueChanges.pipe(this.takeUntilDestroy).subscribe(() => {
      this._dialogRef.updateConfig({
        nzOkDisabled: !this.form.valid
      });
    });
  }
}
