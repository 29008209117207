import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuperEditorGuard, SuperDeveloperGuard } from './guards/super.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./routes/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  {
    path: 'onboarding-flows',
    loadChildren: () =>
      import('./routes/onboarding-flows/onboarding-flows.module').then(
        (m) => m.OnboardingFlowsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'themes',
    loadChildren: () =>
      import('./routes/themes/themes.module').then((m) => m.ThemesModule),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'explore',
    loadChildren: () =>
      import('./routes/explore/explore.module').then((m) => m.ExploreModule),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'upcoming-events',
    loadChildren: () =>
      import('./routes/upcoming-events/upcoming-events.module').then(
        (m) => m.UpcomingEventsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'conversation-starters',
    loadChildren: () =>
      import(
        './routes/conversation-starters/conversation-starters.module'
      ).then((m) => m.ConversationStartersModule),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'example-questions',
    redirectTo: 'workflows'
  },
  {
    path: 'workflows',
    loadChildren: () =>
      import('./routes/example-questions/example-questions.module').then(
        (m) => m.ExampleQuestionsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./routes/email-reports/email-reports.module').then(
        (m) => m.EmailReportsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./routes/subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'pending-invites',
    loadChildren: () =>
      import('./routes/pending-invites/pending-invites.module').then(
        (m) => m.PendingInvitesModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'pending-provisions',
    loadChildren: () =>
      import('./routes/pending-provisions/pending-provisions.module').then(
        (m) => m.PendingProvisionsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'feature-access',
    loadChildren: () =>
      import('./routes/features-enrollment/features-enrollment.module').then(
        (m) => m.FeaturesEnrollmentModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'whitelist-accounts',
    loadChildren: () =>
      import('./routes/whitelist-accounts/whitelist-accounts.module').then(
        (m) => m.WhitelistAccountsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'feature-flags',
    loadChildren: () =>
      import('./routes/feature-flags/feature-flags.module').then(
        (m) => m.FeatureFlagsModule
      ),
    canLoad: [SuperDeveloperGuard]
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import('./routes/announcements/announcements.module').then(
        (m) => m.AnnouncementsModule
      ),
    canLoad: [SuperEditorGuard]
  },
  {
    path: 'system-status',
    loadChildren: () =>
      import('./routes/system-status/system-status.module').then(
        (m) => m.SystemStatusModule
      ),
    canLoad: [SuperDeveloperGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
