import { Injectable } from '@angular/core';
import { IAnalyticsServiceInterface } from 'models';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsServerService implements IAnalyticsServiceInterface {
  constructor() {}

  /**
   * Track a custom event
   * @param eventName Event name
   * @param payload Event payload
   * @param options Event options
   * @param callback Callback to fire after tracking completes
   */
  async track(
    eventName: string,
    payload?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    return null;
  }

  /**
   * Identify a visitor
   * @param userId Unique ID of user
   * @param traits Object of user traits
   * @param options Options to pass to identify call
   * @param callback Callback function after identify completes
   */
  identify(
    userId: string,
    traits?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    return null;
  }
}
