import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from 'uikit';
import { ISlug, ProfilePageBlock } from 'models';
import { SlugService } from '../../services';

@Component({
  selector: 'lib-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  @Input() block: ProfilePageBlock;
  slug: ISlug;

  constructor(private _slug: SlugService) {
    super();
  }

  ngOnInit(): void {
    this._initSlugSubscription();
  }

  private _initSlugSubscription() {
    this._slug
      .getCurrentSlug$()
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => {
        this.slug = slug;
      });
  }
}
