import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropMainButtonComponent } from '../components/drop-main-button';
import { DropMainButtonViewModule } from 'uikit';

@NgModule({
  declarations: [DropMainButtonComponent],
  imports: [CommonModule, DropMainButtonViewModule],
  exports: [DropMainButtonComponent]
})
export class DropMainButtonModule {}
