import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// Lib
import {
  ContentEvent,
  ConversationMessage,
  EventsFilterArgs,
  PaginatedQuerySummary
} from 'models';
import { ContentService } from './content.service';
import { PaymentIntentEstimateType, PaymentIntentType } from './types';

/*
  Content retrieval
*/

@Injectable({
  providedIn: 'root'
})
export class ContentServerService extends ContentService {
  getEventsForCurrentSlug$(
    args: EventsFilterArgs
  ): Observable<PaginatedQuerySummary<ContentEvent>> {
    return from([null]);
  }

  async getPaymentIntentSecret(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ): Promise<PaymentIntentType> {
    return null;
  }

  async getPaymentIntentEstimate(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ): Promise<PaymentIntentEstimateType> {
    return null;
  }
}
