import { Injectable } from '@angular/core';

import { ISearchResults, ISearchService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class SearchServerService implements ISearchService {
  constructor() {}

  async searchContacts(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return null;
  }

  async searchContent(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    excludeContent?: string[];
    includeContent?: string[];
  }): Promise<ISearchResults> {
    return null;
  }

  async searchContactLists(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return null;
  }

  async searchSends(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    includeSentOnly?: boolean;
  }): Promise<ISearchResults> {
    return null;
  }

  async searchExampleQuestions(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return null;
  }
}
