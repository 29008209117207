import { Injectable, TemplateRef } from '@angular/core';

import { IContactModalService } from './contact-modal.service.interface';
import {
  ContactListColumn,
  ContactList,
  ContactView,
  ContactViewFromModal,
  IPSQLContact
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class ContactModalServerService implements IContactModalService {
  async launchContactDetailsDrawer(
    contact: IPSQLContact,
    showDelete = false,
    actionButtonsTpl?: TemplateRef<any>,
    bodyTpl?: TemplateRef<any>
  ): Promise<any> {
    return null;
  }
  launchEditColumnsModal(
    columns: ContactListColumn[]
  ): Promise<ContactListColumn[]> {
    return Promise.resolve([]);
  }

  launchAddOrEditListModal(
    editList?: ContactList,
    saveAsNew = false
  ): Promise<ContactViewFromModal> {
    return Promise.resolve(null);
  }

  launchViewAllListsModal(): Promise<ContactView> {
    return Promise.resolve(null);
  }

  launchBuildListFilters(list: ContactList): Promise<ContactList> {
    return Promise.resolve(null);
  }
}
