import {
  ICreateFeatureFlagDto,
  IFeatureFlag,
  IFeatureFlagResults,
  IUpdateFeatureFlagDto
} from 'models';
import { IFeatureFlagsService } from './feature-flags.service.interface';

export class FeatureFlagsServerService implements IFeatureFlagsService {
  getFeatureFlags(filters?: {
    after?: string;
    offset?: string;
    limit?: number;
  }): Promise<IFeatureFlagResults> {
    return null;
  }

  getFeatureById({ id }: { id: string }): Promise<IFeatureFlag> {
    return null;
  }

  createFeatureFlag(args: ICreateFeatureFlagDto): Promise<IFeatureFlag> {
    return null;
  }

  updateFeatureFlag(
    id: string,
    args: IUpdateFeatureFlagDto
  ): Promise<IFeatureFlag> {
    return null;
  }

  deleteFeatureFlag(id: string): Promise<void> {
    return null;
  }
}
