import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';
import { Auth, User, UserCredential } from '@angular/fire/auth';

// App
import { IUserRole } from 'models';
import { IAuthService } from './auth.service.interface';
import { EmailVerificationPayload, PhoneVerificationPayload } from './types';

@Injectable({
  providedIn: 'root'
})
export class AuthServerService implements IAuthService {
  constructor(private _auth: Auth) {}

  get auth(): Auth {
    return this._auth;
  }

  userRoles$(uid?: string): Observable<IUserRole[]> {
    return from([[]]);
  }

  async userRoles(uid?: string): Promise<IUserRole[]> {
    return [];
  }

  async loginAnonymously(): Promise<UserCredential> {
    return null;
  }

  async logout(): Promise<void> {
    return null;
  }

  async initiateGoogleAuth(onboarding = false): Promise<boolean> {
    return null;
  }

  async completeGoogleLogin(): Promise<UserCredential | null> {
    return null;
  }

  get currentUser(): User {
    return null;
  }

  get user$(): Observable<User> {
    return from([null]);
  }

  get authState$(): Observable<User> {
    return from([null]);
  }

  get isAttemptingReauthFlow$(): Observable<boolean> {
    return from([false]);
  }

  get userLoggedIn() {
    return false;
  }

  get userPhoneLoggedIn() {
    return false;
  }

  async initiatePhoneSignInFlow(
    phoneNumber: string,
    recaptchaToken: string
  ): Promise<boolean> {
    return false;
  }

  async completePhoneLogin(
    input: PhoneVerificationPayload
  ): Promise<UserCredential> {
    return null;
  }

  async initiateEmailSignInOrLinkingFlow({
    email,
    recaptchaToken,
    sendMagicLink
  }: {
    email: string;
    recaptchaToken: string;
    sendMagicLink?: boolean;
  }) {
    return null;
  }

  async promoteSecondOrderEmail({ email }: { email: string }) {
    return null;
  }

  async completeEmailLogin(
    input: EmailVerificationPayload
  ): Promise<UserCredential> {
    return null;
  }
}
