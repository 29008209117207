import { Injectable } from '@angular/core';

// 3rd party
import { from, Observable } from 'rxjs';

// Libs
import {
  IPSQLSendResults,
  PaginatedQuerySummary,
  SendsFilterArgs,
  SingleSend
} from 'models';

// App
import { QuerySummary } from '../observable-data';
import { ISendsStoreService } from './sends-store.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SendsStoreServerService implements ISendsStoreService {
  getSends$ = (
    args: SendsFilterArgs
  ): Observable<PaginatedQuerySummary<SingleSend>> => from([null]);

  getSendById$ = (
    id: string,
    addContent = false
  ): Observable<QuerySummary<SingleSend>> => from([null]);

  getSends = async (filters: SendsFilterArgs): Promise<IPSQLSendResults> => ({
    pageInfo: null,
    edges: new Array(3).fill({ node: null })
  });
}
