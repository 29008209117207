import { Injectable } from '@angular/core';

import { IExampleQuestionsService } from './example-questions.service.interface';
import {
  INlqExampleQuestionCategoriesListingResults,
  INlqExampleQuestionListingResults
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class ExampleQuestionsServerService implements IExampleQuestionsService {
  getCustomerExampleQuestions =
    async (): Promise<INlqExampleQuestionListingResults> => {
      return null;
    };

  getAllExampleQuestions =
    async (): Promise<INlqExampleQuestionCategoriesListingResults> => {
      return null;
    };

  getExampleQuestionsInCategory = async (
    category: string
  ): Promise<INlqExampleQuestionListingResults> => {
    return null;
  };
}
