<lib-device-preview-view
  [platform]="platform"
  [isInteractive]="isInteractive"
  [isScrollable]="isScrollable"
  [showAsAbstract]="showAsAbstract"
  [backgroundCss]="backgroundCss"
  *ngIf="isBrowser"
>
  <norby-report-detail-view
    [report]="report"
    [slug]="slug"
  ></norby-report-detail-view>
</lib-device-preview-view>
