import { Injectable } from '@angular/core';

// 3rd party
import { BehaviorSubject } from 'rxjs';

// Libs
import {
  PaginatedQueryFilters,
  IInboxItemResult,
  IInboxSendMessage,
  IContactSummaryResponseDto,
  IPSQLContactCreate,
  IPSQLContactResponseDto,
  IPSQLContactResults,
  IPSQLContactUpdate,
  IPSQLContact,
  ISendContactBulkMessages,
  ContactsFilterArgs
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class InboxServerService {
  chatViewExpanded = new BehaviorSubject<boolean>(false);

  constructor() {}

  getContacts = async (
    filters: ContactsFilterArgs
  ): Promise<IPSQLContactResults> => {
    return null;
  };

  getContactListings = async (
    filters?: PaginatedQueryFilters
  ): Promise<IPSQLContactResults> => {
    return null;
  };

  getContactById = async ({ id }: { id: string }): Promise<IPSQLContact> => {
    return null;
  };

  async createContact(
    contact: IPSQLContactCreate
  ): Promise<IPSQLContactResponseDto> {
    return null;
  }

  async updateContact(
    id: string,
    contact: IPSQLContactUpdate
  ): Promise<IPSQLContactResponseDto> {
    return null;
  }

  async deleteContact(id: string) {
    return null;
  }

  async mergeContacts(mergeContacts: {
    sourceContactId: string;
    targetContactId: string;
  }) {
    return null;
  }

  async deleteContacts(ids: string[]) {
    return null;
  }

  async markInboxSeen(contactId: string) {
    return null;
  }

  async markInboxItemSeen(ids: string[]) {
    return null;
  }

  getContactInbox = async (
    input: PaginatedQueryFilters & { contactId: string }
  ): Promise<IInboxItemResult> => {
    return null;
  };

  async sendMessage(contactId: string, message: IInboxSendMessage) {
    return null;
  }

  async sendBulkMessages(
    contactId: string,
    messages: ISendContactBulkMessages
  ) {
    return null;
  }

  getContactSummary = async (): Promise<IContactSummaryResponseDto> => {
    return null;
  };

  async retryMessage(contactId: string, inboxItemId: string) {
    return null;
  }

  async listContactNote(contactId: string, limit = 3) {
    return null;
  }

  async addContactNote(contactId: string, body: string) {
    return null;
  }

  async editContactNote(contactId: string, noteId: string, body: string) {
    return null;
  }

  async deleteContactNote(contactId: string, noteId: string) {
    return null;
  }
}
