import { Injectable } from '@angular/core';

// 3rd party
import { from, Observable } from 'rxjs';

// Lib
import { IUserPublicMetadata, IUserRole, IUserSlugNotification } from 'models';
import { IQueriesService } from './queries.service.interface';

@Injectable({
  providedIn: 'root'
})
export class QueriesServerService implements IQueriesService {
  constructor() {}

  getUserById$(id: string): Observable<IUserPublicMetadata> {
    return from([null]);
  }

  userRoleForUser({ userId, slug }: { userId: string; slug: string }) {
    return null;
  }

  getUserRole(userId: string): Promise<IUserRole> {
    return Promise.resolve(null);
  }

  getUserRole$(userId: string): Observable<IUserRole> {
    return from([null]);
  }

  getUserSlugNotification({ userId }: { userId: string }) {
    return null;
  }

  getUserSlugNotifications$({
    userId
  }: {
    userId: string;
  }): Observable<IUserSlugNotification[]> {
    return from([null]);
  }
}
