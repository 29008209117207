import { Injectable, Inject } from '@angular/core';
import { Request } from 'express';
import { BehaviorSubject, distinctUntilChanged, filter, from, map } from 'rxjs';
import { IDeviceService, ROOT_HOST_TOKEN, REQUEST } from 'models';

@Injectable({
  providedIn: 'root'
})
export class DeviceServerService implements IDeviceService {
  private _currentSlug: string;
  private _currentSlug$ = new BehaviorSubject<string>(this.currentSlug);
  slugInfo$ = this._currentSlug$.asObservable().pipe(
    map((slug) => ({
      rootUrl: `${slug}.${this._host}`,
      referUrl: `nor.by/❤/${slug}`,
      slug
    }))
  );
  currentSlug$ = this._currentSlug$.asObservable().pipe(
    filter((slug) => !!slug),
    distinctUntilChanged()
  );
  resize$ = from([]);
  scroll$ = from([]);
  isMobile$ = from([false]);

  constructor(
    @Inject(ROOT_HOST_TOKEN) protected _host: string,
    @Inject(REQUEST) protected _request: Request
  ) {
    const invalidHostname =
      !this.hostname ||
      this.hostname === 'localhost' ||
      this.hostname.includes('ngrok') ||
      this.hostname.startsWith('192');
    this._currentSlug = invalidHostname
      ? 'teamnorbyexp'
      : this._clean(this.hostname);
    this._currentSlug$.next(this._currentSlug);
  }

  private _clean(input: string) {
    const stripWww =
      input.startsWith('www.') && input.length > 3 ? input.substring(4) : input;
    return stripWww.split('.')[0];
  }

  forceSlug(slug: string) {
    this._currentSlug = slug;
    this._currentSlug$.next(slug);
  }

  get timezone(): string {
    return 'UTC';
  }

  get history(): History {
    return null;
  }

  get hostname(): string {
    return this._request?.hostname;
  }

  get currentSlug(): string {
    return this._currentSlug;
  }

  get currentHost(): string {
    if (
      !this.hostname ||
      this.hostname === 'localhost' ||
      this.hostname.includes('ngrok') ||
      this.hostname.startsWith('192')
    ) {
      return 'localhost';
    }

    return this._host;
  }

  get currentSiteRootURL(): string {
    if (
      !this.hostname ||
      this.hostname === 'localhost' ||
      this.hostname.includes('ngrok') ||
      this.hostname.startsWith('192')
    ) {
      return 'localhost';
    }

    return `${this.currentSlug}.${this._host}`;
  }
}
