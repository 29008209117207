import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// 3rd party
import {
  NzDrawerService,
  NzDrawerRef,
  NzDrawerOptions
} from 'ng-zorro-antd/drawer';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import { filter, firstValueFrom } from 'rxjs';

// Libs
import { IDrawerService, IUserPublicMetadata } from 'models';
import { FormDrawer } from 'customer-uikit';
import { AuthService } from '../auth';
import { UiService } from '../ui';

export const PAGE_DRAWER_LEVEL_OVERLAP = 32;

@Injectable({
  providedIn: 'root'
})
export class DrawerService implements IDrawerService {
  private _activeDrawers: NzDrawerRef[] = [];

  // Lazy loaded
  editProfileComponent;
  addUserPhoneComponent;

  constructor(
    private _router: Router,
    private _drawer: NzDrawerService,
    private _dialog: NzModalService,
    private _auth: AuthService,
    private _ui: UiService
  ) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.closeDrawer());
  }

  async closeDrawer() {
    for (const drawer of this._activeDrawers) {
      const cmp = drawer.getContentComponent();
      if (cmp instanceof FormDrawer && !(await cmp.onCancel())) {
        break;
      } else {
        drawer.close();
      }
    }
  }

  createDrawer(component: any, options: NzDrawerOptions): Promise<any> {
    const prevDrawers = this._activeDrawers;
    const newDrawer = this._drawer.create<typeof component, string>({
      nzContent: component,
      nzCloseOnNavigation: false,
      nzBodyStyle: {
        padding: '0',
        height: '100vh',
        maxHeight: '-webkit-fill-available'
      },
      nzWrapClassName: 'norby-page-drawer',
      nzPlacement: 'bottom',
      nzWidth: '100%',
      nzHeight: '100%',
      nzClosable: false,
      nzTitle: null,
      nzOnCancel: async () => {
        const cmp = newDrawer.getContentComponent();
        if (cmp instanceof FormDrawer) {
          return await cmp.onCancel();
        }

        return true;
      },
      ...options
    });

    prevDrawers.push(newDrawer);
    return newDrawer.afterClose.toPromise().then((value) => {
      prevDrawers.pop();
      return value;
    });
  }

  async launchEditProfile(userProfile?: IUserPublicMetadata) {
    if (!this.editProfileComponent) {
      this._ui.setLoading(true);
      const { EditProfileComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/edit-profile'
      );
      this.editProfileComponent = EditProfileComponent;
      this._ui.setLoading(false);
    }

    const component = this.editProfileComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: `${!userProfile?.displayName?.length ? 'Complete' : 'Edit'} your profile`,
      nzData: { userProfile }
    } as ModalOptions);
    const result = await firstValueFrom(dialogRef.afterClose);
    return result;
  }

  async launchAddUserPhoneNumberDialog() {
    if (!this.addUserPhoneComponent) {
      this._ui.setLoading(true);
      const { AddUserPhoneNumberComponent } = await import(
        '../../entry-points/add-user-phone-number'
      );

      this.addUserPhoneComponent = AddUserPhoneNumberComponent;
      this._ui.setLoading(false);
    }

    const component = this.addUserPhoneComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Add your number',
      nzOkDisabled: true,
      nzOkText: 'Next'
    });

    return await firstValueFrom(dialogRef.afterClose);
  }

  async logoutAndGoHome(): Promise<void> {
    await this._auth.logout();
    this._router.navigate(['/']);
  }
}
