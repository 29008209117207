import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { IUserContent, ContentSignup } from 'models';
import { BaseComponent } from 'uikit';

import { UserService } from '../../services';

/*
  Creates a main button (register/rsvp/purchase) for a given drop
  Manages its own subscription to user content
*/

@Component({
  selector: 'lib-drop-main-button',
  templateUrl: './drop-main-button.component.html',
  styleUrls: ['./drop-main-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropMainButtonComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() drop: ContentSignup;
  userContent: IUserContent;
  hasLoaded = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _user: UserService
  ) {
    super();
  }

  ngOnChanges(): void {
    super.ngOnChanges();

    if (this.drop?.contentId)
      this._user
        .getUserContent$(this.drop.contentId)
        .pipe(this.takeUntilChanges)
        .subscribe((e) => {
          this.userContent = e;
          this.hasLoaded = true;
          this._cdr.detectChanges();
        });
  }
}
