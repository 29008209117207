import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// Libs

import {
  PaginatedQueryFilters,
  PaginatedQuerySummary,
  Conversation,
  PaginatedMessageFilters,
  ConversationMessageGroup
} from 'models';

import { IAiStoreService } from './ai-store.service.interface';
import { QuerySummary } from '../observable-data';

@Injectable({
  providedIn: 'root'
})
export class AiStoreServerService implements IAiStoreService {
  getConversations$(
    args: PaginatedQueryFilters
  ): Observable<PaginatedQuerySummary<Conversation>> {
    return from([null]);
  }

  getConversationMessagesById$(
    args: PaginatedMessageFilters
  ): Observable<PaginatedQuerySummary<ConversationMessageGroup>> {
    return from([null]);
  }

  getConversationById$({
    id
  }: {
    id: string;
  }): Observable<QuerySummary<Conversation>> {
    return from([null]);
  }

  getForYou$(): Observable<any> {
    return from([null]);
  }
}
