import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  EditEmailTextBlockViewComponent,
  EditNlqPromptBlockViewComponent,
  EditNlqSummaryBlockViewComponent
} from '../components/templates/email-block-edit-views';
import { NorbyRichTextEditorViewModule } from './norby-rich-text-editor-view.module';

@NgModule({
  declarations: [
    EditEmailTextBlockViewComponent,
    EditNlqPromptBlockViewComponent,
    EditNlqSummaryBlockViewComponent
  ],
  imports: [CommonModule, NorbyRichTextEditorViewModule, ReactiveFormsModule],
  exports: [
    EditEmailTextBlockViewComponent,
    EditNlqPromptBlockViewComponent,
    EditNlqSummaryBlockViewComponent
  ]
})
export class EmailBlockEditViewsModule {}
