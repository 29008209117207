import { Injectable } from '@angular/core';

// App
import { IBillingServiceInterface } from './billing.service.interface';
import {
  AddOnType,
  IAddOnsResult,
  IAttachCardPaymentMethod,
  ISubscriptionSchedule,
  IEstimationResult,
  PaginatedQueryFilters,
  ProjectPeriod,
  ProjectTiersType,
  IUsageSummary,
  SubscriptionChangeReason
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class BillingServerService implements IBillingServiceInterface {
  async attachPaymentCard(payload: IAttachCardPaymentMethod) {}

  async acceptTerms(showConfirmation = false) {}

  async detachPaymentCard(id: string) {}

  async setDefaultPaymentMethod(id: string) {}

  async attachAddon(addonType: AddOnType) {}

  async detachAddon(addonType: AddOnType) {}

  fetchAddons = async (
    filters?: PaginatedQueryFilters
  ): Promise<IAddOnsResult> => {
    return null;
  };

  async estimateSubscriptionChange(
    targetProjectTier: ProjectTiersType,
    targetProjectPeriod: ProjectPeriod
  ): Promise<IEstimationResult> {
    return null;
  }

  async changeSubscription(
    targetProjectTier: ProjectTiersType,
    targetProjectPeriod: ProjectPeriod,
    changeReason?: SubscriptionChangeReason
  ): Promise<boolean> {
    return false;
  }

  async fetchDowngradeSchedules(): Promise<ISubscriptionSchedule[]> {
    return null;
  }

  async cancelPlanChange(): Promise<void> {}

  async fetchUsageSummary(): Promise<IUsageSummary> {
    return null;
  }

  async checkPromoValidity(promotionCode: string) {}

  async redeemPromo(promotionCode: string) {}

  async getBillingEstimate() {}
}
