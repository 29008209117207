import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileComponent } from '../components';
import { ProfileViewModule } from 'uikit';

@NgModule({
  declarations: [ProfileComponent],
  imports: [CommonModule, ProfileViewModule],
  exports: [ProfileComponent]
})
export class ProfileModule {}
